import { takeLeading } from 'redux-saga/effects';

import { ICertification, ICertificationRequest } from '../../../models';
import Api from '../../api/Api';
import { EndpointSaga, IEndpointSagaDictionary } from '../../endpoint';
import { GetCertificationActions } from './actions';

export const sagas: IEndpointSagaDictionary = {
  GetCertificationSaga: new EndpointSaga<ICertificationRequest, ICertification[]>(
    GetCertificationActions,
    Api.GET_CERTIFICATIONS,
    takeLeading
  )
};
