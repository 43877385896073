import React, { useCallback, useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import { FormattedMessage } from 'react-intl';

import './SearchTextBox.scss';

interface ISuggestionsProp {
  data: string[];
  onResults: (value: string) => void;
  targetId: string;
  addSuggestion: (event: any) => void;
  hideSuggestions: (event: any) => void;
}

export const GlobalResults: React.FunctionComponent<ISuggestionsProp> = props => {
  const { onResults, addSuggestion, hideSuggestions } = props;
  const [escape, setEscape] = useState(false);
  let list = props.data;
  list = [...new Set(list)];
  const suggestionId = useId('suggestions');

  const onListBlur = useCallback(
    (event: any) => {
      event.persist();
      if (event.relatedTarget) {
        const { className } = event.relatedTarget;
        if (className !== 'item') {
          hideSuggestions(event);
        }
      }
    },
    [hideSuggestions]
  );

  const onEscapeKeyPress = (event: any) => {
    const code = event.keyCode || event.which;
    if (code === 27) {
      setEscape(true);
    }
  };

  return (
    <div>
      <label htmlFor="#suggestions">
        <FormattedMessage defaultMessage="Suggestions available. Tab to explore. Enter to select." />
      </label>
      <ul id={suggestionId} className="items" aria-labelledby={suggestionId} onBlur={onListBlur}>
        {list.map((item, index) => (
          //eslint-disable-next-line
          <li
            aria-label={item}
            key={index}
            id={index.toString()}
            role="listitem"
            //eslint-disable-next-line
            tabIndex={0}
            className={escape ? 'itemHidden' : 'item'}
            //eslint-disable-next-line
            onKeyDown={addSuggestion}
            //eslint-disable-next-line
            onClick={() => onResults(item)}
            onKeyUp={onEscapeKeyPress}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default GlobalResults;
