import { useDispatch } from 'react-redux';
import { ActionCreator, bindActionCreators } from 'redux';

import { EndpointActions } from './';

export const useEndpointActions = (endpointActions: Array<EndpointActions<any, any>> = []): Array<ActionCreator<any>> => {
  const dispatch = useDispatch();

  if (endpointActions.length) {
    const actionMap = endpointActions.reduce((map: any, action: EndpointActions<any, any>) => {
      map[action.name] = action.Execute;
      return map;
    }, {});

    const boundActions = bindActionCreators(actionMap, dispatch);
    return Object.values(boundActions);
  } else {
    return [];
  }
};
