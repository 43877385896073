import { takeLeading } from 'redux-saga/effects';

import { ICopilotTokenRequest, ICopilotTokenResponse } from '../../../models';
import Api from '../../api/Api';
import { EndpointSaga, IEndpointSagaDictionary } from '../../endpoint';
import { GetCopilotActions } from './actions';

export const sagas: IEndpointSagaDictionary = {
  GetCertificationSaga: new EndpointSaga<ICopilotTokenRequest, ICopilotTokenResponse>(GetCopilotActions, Api.GET_COPILOTTOKENS, takeLeading)
};
