import { AnyAction, Reducer } from 'redux';

import {
  IRequestResponse,
  IUserProfile,
  IUserEmailProfile,
  ICustomErrorMessage,
  ITokensAvailable,
  ILearnerIdRequest,
  IUserSSOProfile
} from '../../../models';
import { EndpointStateFactory, EndpointStore, IEndpointState } from '../../endpoint';
import {
  CreateProfileActions,
  GetProfileActions,
  UpdateProfileActions,
  EmailValidationActions,
  EmailVerificationActions,
  VerificationCodeActions,
  EmailServiceStatusActions,
  DeleteAccountActions,
  UpdateWorkEmailAddressActions,
  VerifyWorkEmailAddressActions,
  CheckUserIsSupportUserOrNotActions,
  GETTokensAvailableActions,
  GetSSOStatusActions,
  VerifyCleanupUserActions,
  UnlinkEmailAddressErrorActions,
  GetSSOLinkedAccountsActions
} from './actions';
import { sagas } from './sagas';
import { ErrorMessageMapper } from './../../../utilities';
export * from './actions';

/** The name of the state, this will be the Redux property that data is stored under */
const storeName = 'UserStore';

export interface IUserState extends IEndpointState {
  /** The user profile state */
  readonly profile: IUserProfile;
  readonly isSupportUserOrNot: boolean | null;
  readonly emailprofile: IUserEmailProfile;
  readonly ssoprofile: IUserSSOProfile;
  readonly isProfileExists: boolean | null;
  readonly isEmailSend: boolean | null;
  readonly isUnlinked: boolean | null;
  readonly isEmailSendForWorkEmail: boolean | null;
  readonly isWorkEmailUpdate: boolean | null;
  readonly isVerified: boolean | null;
  readonly isSSOError: boolean | null;
  readonly isIncomingSSOError: boolean | null;
  readonly isVerificationError: boolean | null;
  readonly isAccountLinkingSuccess: boolean | null;
  readonly response: IRequestResponse<any>;
  readonly learnerId: ILearnerIdRequest;
  readonly verifyCleanupUserResponse: ICustomErrorMessage | null;
  readonly unlinkEmailAddressErrorResponse: ICustomErrorMessage | null;
  readonly accountUnlinkingResponse: ICustomErrorMessage | null;
  readonly customErrorMessage: ICustomErrorMessage | null;
  readonly isEmailServiceAvailable: boolean | true;
  readonly tokensAvailable: ITokensAvailable;
}

/** IState acts only as a helper type to make store logic easier to copy and paste */
type IState = IUserState;

/**
 * The initial state of the endpoint store. Endpoint stores have base states (ex: isFetching, isFetched, etc.).
 *  We use the EndointStateFactory to make sure to populate these values with the correct defaults
 */
export const initialUserState: IState = new EndpointStateFactory<IState>().getInitialState({
  profile: {
    associationId: '',
    displayName: '',
    emailAddress: '',
    activeLearningPlanId: '',
    isTOSAccepted: false,
    id: '',
    TPID: '',
    oid: '',
    programs: [],
    hasLinkedAccount: false,
    role: '',
    roleName: '',
    timezone: '',
    language: '',
    workEmail: '',
    firstName: '',
    lastName: '',
    country: '',
    preferredEmailAddress: '',
    isKoreaPrivacyAccepted: false,
    esiTerms: { id: '', isESITermsAccepted: false, acceptedDateTime: undefined }
  },
  emailprofile: {
    tpid: '',
    domain: '',
    companyName: '',
    ESIIndicator: '',
    ESIMember: false,
    enableCopilot: false,
    esi: false,
    isAad: null,
    lpId: '',
    isMSA: false,
    authority: '',
    isMigrateCandidate: false
  },
  ssoprofile: {
    isDomainEligibleForSso: false,
    linkedAccounts: null
  },
  isProfileExists: null,
  isEmailSend: null,
  isEmailSendForWorkEmail: null,
  isWorkEmailUpdate: null,
  isVerified: null,
  isVerificationError: null,
  isSSOError: null,
  isIncomingSSOError: null,
  isAccountLinkingSuccess: null,
  customErrorMessage: null,
  accountUnlinkingResponse: null,
  verifyCleanupUserResponse: null,
  unlinkEmailAddressErrorResponse: null,
  isUnlinked: false,
  isEmailServiceAvailable: undefined,
  tokensAvailable: {
    availableTokens: undefined,
    displayRequestForm: false,
    lxpPuid: '',
    tokenRegenerationDate: '',
    maximumTokens: undefined,
    additionalTokenRequestStatus: ''
  }
});

/**
 * Reducer responsible for managing the slice of state that belongs to the store
 *
 * @param state - Initial user state
 * @param action - The action
 */
const reducer: Reducer<IState> = (state: IState = initialUserState, action: AnyAction): IState => {
  switch (action.type) {
    case GetProfileActions.ActionTypes.ExecuteError:
      if (action.meta && action.meta.status === 404) {
        return {
          ...state,
          isProfileExists: false
        };
      } else {
        return {
          ...state,
          isProfileExists: false,
          isError: true,
          customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
        };
      }
    case CreateProfileActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isProfileExists: false,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case UpdateProfileActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case DeleteAccountActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: false,
        accountUnlinkingResponse: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case DeleteAccountActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        accountUnlinkingResponse: {
          statusCode: 200
        },
        profile: {
          ...state.profile
        }
      };
    case CheckUserIsSupportUserOrNotActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        isSupportUserOrNot: action.payload
      };
    case CheckUserIsSupportUserOrNotActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isSupportUserOrNot: false,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case GetProfileActions.ActionTypes.ExecuteSuccess:
    case CreateProfileActions.ActionTypes.ExecuteSuccess:
    case UpdateProfileActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        isProfileExists: true,
        profile: action.payload
      };
    case EmailValidationActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        emailprofile: action.payload
      };
    case EmailValidationActions.ActionTypes.ExecuteError:
      if (action.meta) {
        return {
          ...state,
          emailprofile: action.meta.data,
          isError: true,
          customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
        };
      } else {
        return state;
      }
    case GETTokensAvailableActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        tokensAvailable: action.payload
      };
    case GETTokensAvailableActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case VerifyCleanupUserActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        isUnlinked: true,
        verifyCleanupUserResponse: {
          statusCode: 200
        }
      };
    case VerifyCleanupUserActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        verifyCleanupUserResponse: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case VerifyCleanupUserActions.ActionTypes.Clear:
      return {
        ...state,
        isError: false,
        verifyCleanupUserResponse: null
      };
    case UnlinkEmailAddressErrorActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        isUnlinked: true,
        unlinkEmailAddressErrorResponse: {
          statusCode: 200
        }
      };
    case UnlinkEmailAddressErrorActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        unlinkEmailAddressErrorResponse: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case EmailVerificationActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        isEmailSend: true
      };
    case EmailVerificationActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isEmailSend: false,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case EmailVerificationActions.ActionTypes.Clear:
      return {
        ...state,
        isEmailSend: null,
        isError: action.payload || false,
        customErrorMessage: null,
        isVerified: null,
        isVerificationError: null
      };
    case VerifyWorkEmailAddressActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        isEmailSendForWorkEmail: true,
        isVerified: null,
        isVerificationError: null
      };
    case VerifyWorkEmailAddressActions.ActionTypes.Clear:
      return {
        ...state,
        isEmailSendForWorkEmail: null,
        isVerified: null,
        isVerificationError: null
      };
    case UpdateWorkEmailAddressActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        isWorkEmailUpdate: true,
        isEmailSendForWorkEmail: false,
        isVerified: true,
        isVerificationError: false
      };
    case UpdateWorkEmailAddressActions.ActionTypes.ExecuteError:
      if (action.meta.status === 400) {
        return {
          ...state,
          isVerified: false,
          isVerificationError: true
        };
      } else {
        return { ...state, isError: true, customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta) };
      }
    case VerifyWorkEmailAddressActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isEmailSendForWorkEmail: false,
        isError: true,
        isVerificationError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case EmailServiceStatusActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        isEmailServiceAvailable: action.payload
      };
    case EmailServiceStatusActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true
      };
    case VerificationCodeActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        isVerified: true,
        isVerificationError: false
      };
    case VerificationCodeActions.ActionTypes.ExecuteError:
      if (action.meta.status === 400 || action.meta.status === 404) {
        return {
          ...state,
          isVerified: false,
          isVerificationError: true
        };
      } else {
        return { ...state, isError: true, customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta) };
      }
    case GetSSOStatusActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        isSSOError: false,
        ssoprofile: { ...state.ssoprofile, isDomainEligibleForSso: action.payload.isDomainEligibleForSso }
      };
    case GetSSOStatusActions.ActionTypes.ExecuteError:
      return {
        ...state,
        ssoprofile: {
          ...state.ssoprofile,
          isDomainEligibleForSso: false
        },
        isSSOError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case GetSSOLinkedAccountsActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        isIncomingSSOError: false,
        ssoprofile: {
          ...state.ssoprofile,
          linkedAccounts: action.payload.map((profile: IUserProfile) => {
            return { workEmail: profile.workEmail, email: profile.emailAddress };
          })
        }
      };
    case GetSSOLinkedAccountsActions.ActionTypes.ExecuteError:
      if (action.meta && action.meta.status === 500 && action.meta.data?.statusCode === 404) {
        return { ...state, isIncomingSSOError: false, ssoprofile: { ...state.ssoprofile, linkedAccounts: [] } };
      }
      return { ...state, customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta), isIncomingSSOError: true };
    default:
      return state;
  }
};

/** Responsible for handling the related state in the Redux store */
export const UserStore = new EndpointStore<IState>(storeName, initialUserState, reducer, sagas, [
  GetProfileActions,
  CreateProfileActions,
  UpdateProfileActions,
  EmailValidationActions,
  GetSSOStatusActions,
  DeleteAccountActions
]);
