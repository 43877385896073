import {
  ILearningPath,
  ILearningPathCard,
  ILearningPathGroups,
  IFilterInputs,
  IMSClassesCatalog,
  ILearningPartners,
  IFilterInputMyDeliveries
} from '../../../models';
import { EndpointActions } from '../../endpoint';

export const GetLearningPathActions = new EndpointActions<string, ILearningPath>('GetLearningPath');
export const GetLearningPathGroupsActions = new EndpointActions<string, ILearningPathGroups[]>('GetLearningPathGroups');
export const GetMyDeliveriesActions = new EndpointActions<IFilterInputMyDeliveries, IMSClassesCatalog>('GetMyDeliveries');
export const GetLearningPathCardActions = new EndpointActions<string, ILearningPathCard[]>('GetLearningPathCards');
export const MSCatalogActions = new EndpointActions<IFilterInputs, IMSClassesCatalog>('MSCatalog');
export const MSSingleCatalogActions = new EndpointActions<IFilterInputs, IMSClassesCatalog>('MSSingleCatalog');
export const GetLearningPartnersActions = new EndpointActions<string, ILearningPartners[]>('GetLearningParners');
export const GetSearchDeliveriesStatusActions = new EndpointActions<IFilterInputMyDeliveries, IMSClassesCatalog>(
  'GetSearchDeliveriesStatus'
);
