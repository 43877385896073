import { takeLeading } from 'redux-saga/effects';

import {
  ICreateProfileRequest,
  IUpdateProfileRequest,
  IUserProfile,
  IEmailValidationRequest,
  IUserEmailProfile,
  IEmailVerificationRequest,
  IVerificationCodeRequest,
  IWorkEmailVerificationCodeRequest,
  IUpdateEmailVerificationRequest,
  ITokensAvailable,
  ILearnerIdRequest,
  IUserSSOEligibility,
  IVerifyCleanupUserRequest,
  IUnlinkEmailAddressErrorRequest
} from '../../../models';
import Api from '../../api/Api';
import { EndpointSaga, IEndpointSagaDictionary } from '../../endpoint';
import {
  CreateProfileActions,
  GetProfileActions,
  UpdateProfileActions,
  EmailVerificationActions,
  EmailValidationActions,
  EmailServiceStatusActions,
  VerificationCodeActions,
  DeleteAccountActions,
  UpdateWorkEmailAddressActions,
  VerifyWorkEmailAddressActions,
  CheckUserIsSupportUserOrNotActions,
  GETTokensAvailableActions,
  GetSSOStatusActions,
  VerifyCleanupUserActions,
  UnlinkEmailAddressErrorActions,
  GetSSOLinkedAccountsActions
} from './actions';

export const sagas: IEndpointSagaDictionary = {
  CheckUserIsSupportUserOrNotSaga: new EndpointSaga<string, boolean>(CheckUserIsSupportUserOrNotActions, Api.GET_USER_PARTOF, takeLeading),
  GetProfileSaga: new EndpointSaga<string, IUserProfile>(GetProfileActions, Api.GET_PROFILE, takeLeading),
  CreateProfileSaga: new EndpointSaga<ICreateProfileRequest, IUserProfile>(CreateProfileActions, Api.CREATE_PROFILE, takeLeading),
  UpdateProfileSaga: new EndpointSaga<IUpdateProfileRequest, IUserProfile>(UpdateProfileActions, Api.UPDATE_PROFILE, takeLeading),
  EmailValidationSaga: new EndpointSaga<IEmailValidationRequest, IUserEmailProfile>(
    EmailValidationActions,
    Api.EMAIL_VALIDATION,
    takeLeading
  ),
  GETTokensAvailableSaga: new EndpointSaga<ILearnerIdRequest, ITokensAvailable>(
    GETTokensAvailableActions,
    Api.TOKENS_AVAILABLE,
    takeLeading
  ),
  EmailVerificationSaga: new EndpointSaga<IEmailVerificationRequest, any>(EmailVerificationActions, Api.EMAIL_VERIFICATION, takeLeading),
  VerificationCodeSega: new EndpointSaga<IVerificationCodeRequest, any>(VerificationCodeActions, Api.VERIFIY_CODE, takeLeading),
  EmailServiceStatusSaga: new EndpointSaga<string, boolean>(EmailServiceStatusActions, Api.EMAIL_SERVICE_STATUS, takeLeading),
  DeleteAccountSaga: new EndpointSaga<any, any>(DeleteAccountActions, Api.DELETE_ACCOUNT, takeLeading),
  VerifyWorkEmailAddressSaga: new EndpointSaga<IUpdateEmailVerificationRequest, any>(
    VerifyWorkEmailAddressActions,
    Api.VERIFY_WORKEMAILADDRESS,
    takeLeading
  ),
  VerifyCleanupUserSaga: new EndpointSaga<IVerifyCleanupUserRequest, any>(VerifyCleanupUserActions, Api.VERIFY_CLEANUP_USERS, takeLeading),

  UnlinkEmailAddressErrorSaga: new EndpointSaga<IUnlinkEmailAddressErrorRequest, any>(
    UnlinkEmailAddressErrorActions,
    Api.Unlink_EmailAddress_Error,
    takeLeading
  ),

  UpdateWorkEmailAddressSaga: new EndpointSaga<IWorkEmailVerificationCodeRequest, any>(
    UpdateWorkEmailAddressActions,
    Api.UPDATE_WORKEMAILADDRESS,
    takeLeading
  ),
  GetSSOStatusSaga: new EndpointSaga<string, IUserSSOEligibility>(GetSSOStatusActions, Api.GET_SSO_STATUS, takeLeading),
  GetSSOLinkedAccountsSaga: new EndpointSaga<any, IUserProfile[]>(GetSSOLinkedAccountsActions, Api.GET_SSO_LINKED_ACCOUNTS, takeLeading)
};
