import {
  ICountry,
  ILanguage,
  IRole,
  ITimezone,
  IModality,
  INotifications,
  IESIAgreement,
  IVerificationEmailRequest
} from '../../../models';
import { IMeasureUpLTISettings } from '../../../models/MeasureUpLTISettings';
import { EndpointActions } from '../../endpoint';

export const GetLanguagesActions = new EndpointActions<string, ILanguage[]>('GetLanguages');
export const GetTimeZonesActions = new EndpointActions<string, ITimezone[]>('GetTimezones');
export const GetModalitiesActions = new EndpointActions<string, IModality[]>('GetModalities');
export const GetRolesActions = new EndpointActions<string, IRole[]>('GetRoles');
export const GetCountryActions = new EndpointActions<string, ICountry[]>('GetCountry');
export const GetMeasureUpLTISettingsActions = new EndpointActions<string, IMeasureUpLTISettings[]>('GetMeasureUpLTISettings');
export const GetNotificationsActions = new EndpointActions<string, INotifications[]>('GetNotificationsActions');
export const GetESIAgreementActions = new EndpointActions<string, IESIAgreement>('GetESIAgreementActions');
export const GetEmailCodeVerificationStatusActions = new EndpointActions<IVerificationEmailRequest, boolean>(
  'GetEmailCodeVerificationStatus'
);
