// eslint-disable-next-line import/no-unresolved
import { ActionMatchingPattern } from '@redux-saga/types';

import { ActionPattern, call, ForkEffect, put } from 'redux-saga/effects';

import { IRequestResponse } from '../../models';
import { EndpointActions, IAction } from './EndpointActions';

type EffectCreator = <P extends ActionPattern>(pattern: P, worker: (action: ActionMatchingPattern<P>) => any) => ForkEffect;
type ApiFunction<RequestBody, ResponseBody> = (parameters: RequestBody) => Promise<IRequestResponse<ResponseBody>>;
type ApiFunctionWithoutRequestBody<ResponseBody> = () => Promise<IRequestResponse<ResponseBody>>;

export interface IEndpointSagaDictionary {
  [key: string]: EndpointSaga<any, any>;
}

/**
 * A class that implements a default saga against an endpoint.
 * Pieces of the default behavior can be overriden by creating a new class and extending from this class.
 */
export class EndpointSaga<RequestBody, ResponseBody> {
  /** The endpoint actions the saga will act on */
  protected endpointActions: EndpointActions<RequestBody, ResponseBody>;

  /** The Api function that will be executed */
  private _apiFunction: Function;

  /** The effect creator to apply when watching for the endpoint actions to occur */
  private _effectCreator: EffectCreator;

  constructor(
    endpointActions: EndpointActions<RequestBody, ResponseBody>,
    apiFunction: ApiFunction<RequestBody, ResponseBody>,
    effectCreator: EffectCreator
  ) {
    this.endpointActions = endpointActions;
    this._apiFunction = apiFunction;
    this._effectCreator = effectCreator;

    // Generator functions have a context that is not bound to the 'this' keyword
    this.handleExecution = this.handleExecution.bind(this);
    this.watcher = this.watcher.bind(this);
    this.onSuccessExecuted = this.onSuccessExecuted.bind(this);
    this.onErrorExecuted = this.onErrorExecuted.bind(this);
  }

  /**
   * Determine how to watch the execution scenario
   */
  public *watcher() {
    yield this._effectCreator(this.endpointActions.ActionTypes.Execute, this.handleExecution);
  }

  /**
   * Default implementation of the saga to handle the Execution action workflow.
   *
   * @param action The action being evaluated
   */
  protected *handleExecution(action: IAction<RequestBody>): IterableIterator<any> {
    try {
      let response: IRequestResponse<ResponseBody> | any;
      if (action.payload) {
        const apiFunctionWithBody = this._apiFunction as ApiFunction<RequestBody, ResponseBody>;
        response = yield call(apiFunctionWithBody, action.payload);
      } else {
        const apiFunctionWithoutBody = this._apiFunction as ApiFunctionWithoutRequestBody<ResponseBody>;
        response = yield call(apiFunctionWithoutBody);
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { config, data, ...responseMeta } = response;
      yield put(this.endpointActions.ExecuteSuccess(response.data, responseMeta));
      yield* this.onSuccessExecuted(action, response);
    } catch (err) {
      if (err.response) {
        // The request was made and the server responded with a
        // status code that falls out of the range of 2xx
        yield put(this.endpointActions.ExecuteError(err.stack!, err.response));
        yield* this.onErrorExecuted(action, err.stack!, err.response);
      } else {
        // Something happened in setting up the request and triggered an Error
        const unknownErrorMsg = 'An unknown error occured.';
        yield put(this.endpointActions.ExecuteError(unknownErrorMsg));
        yield* this.onErrorExecuted(action, unknownErrorMsg);
      }
    }
  }

  /**
   * A hook meant to be overridden by a subclass to extend the default behavior of the EndpointSaga.
   * This function is executed by the Saga after a ExecuteSuccess action has been dispatched.
   *
   * @param action The action that was successful
   * @param response The response from the Api method
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected *onSuccessExecuted(action: IAction<RequestBody>, response: IRequestResponse<ResponseBody>): IterableIterator<any> {
    // Left intentionally blank.
    //  This hook should be overridden by a subclass to get custom behavior integrated with the default Saga
  }

  /**
   * A hook meant to be overridden by a subclass to extend the default behavior of the EndpointSaga.
   * This function is executed by the Saga after a ExecuteError action has been dispatched.
   *
   * @param action The action that was unsuccessful
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  protected *onErrorExecuted(
    action: IAction<RequestBody>,
    errorMessage: string,
    response?: IRequestResponse<ResponseBody>
  ): IterableIterator<any> {
    // Left intentionally blank.
    //  This hook should be overridden by a subclass to get custom behavior integrated with the default Saga
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}
