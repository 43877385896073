import { initializeIcons } from '@fluentui/react';
import React, { useEffect, useState, useCallback } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { renderRoutes } from 'react-router-config';
import { shallowEqual, useSelector } from 'react-redux';

import FeatureFlagValues from '../../utilities/featureFlagValues';
import { IApplicationState } from '../../store';
import CopilotBot from '../CopilotBot/CopilotBot';
import { routeConfig } from '../../Routes';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import SessionTimeout from '../SessionTimeout/SessionTimeout';
import { SkipNavContent } from '../SkipNav/SkipNavContent';
import { SkipNavLink } from '../SkipNav/SkipNavLink';
import { sessionTimeout } from '../../utilities/Constants';

initializeIcons();

export interface IAppProps {
  /** Determines whether to render the Header */
  showHeader?: boolean;

  /** Determines whether to render the Header */
  showFooter?: boolean;

  /** Determines if user is logged in */
  showPersona?: boolean;
}

/**
 * App component used to bootstrap the entire application
 *
 * @param props - The properties
 */
const App: React.FunctionComponent<IAppProps> = props => {
  const { showHeader, showFooter, showPersona } = props;
  const timeout = sessionTimeout;
  const [isCopilotVisible, setCopilotVisible] = React.useState<boolean>(true);
  const [isEnableCopilotForUserFlag] = useSelector(
    (state: IApplicationState) => [state.UserStore.emailprofile.enableCopilot],
    shallowEqual
  );
  const [isIdle, setIsIdle] = useState(false);
  const [openSessionTimeout, setOpenSessionTimeout] = useState(false);

  const handleAction = () => {
    setIsIdle(false);
  };

  const handleIdle = () => {
    if (showHeader && showFooter && showPersona) {
      setIsIdle(true);
    }
    start();
  };

  const handleContinueSession = () => {
    setOpenSessionTimeout(false);
    reset();
  };

  useEffect(() => {
    const getPathName = window.location.pathname;
    if (getPathName === '/about/' || getPathName === '/esiAgreement/' || getPathName === '/AadLogin/') {
      setCopilotVisible(false);
    }
  }, []);

  useEffect(() => {
    if (isIdle) {
      setOpenSessionTimeout(true);
    }
  }, [isIdle]);

  const { reset, start } = useIdleTimer({
    timeout,
    onAction: handleAction,
    onIdle: handleIdle
  });

  const changeCopilotVisibility = (val: any) => {
    setCopilotVisible(val);
  };

  const toggleCopilotVisibility = useCallback(() => {
    setCopilotVisible(!isCopilotVisible);
  }, [isCopilotVisible]);

  const isDisableELAUnAuthFlow = FeatureFlagValues().disableELAUnAuthFlow;
  const isCopilotVisibleForUnauthFlag = isDisableELAUnAuthFlow === Boolean(isDisableELAUnAuthFlow) ? !isDisableELAUnAuthFlow : false;

  return (
    <div className="wrapper">
      {showHeader && (
        <React.Fragment>
          <SkipNavLink />
          <Header
            showPersona={showPersona!}
            toggleCopilotVisibility={toggleCopilotVisibility}
            enableCopilotForAccount={isEnableCopilotForUserFlag}
          />
        </React.Fragment>
      )}
      <div className="appWrapper">
        <div className="app">
          <SessionTimeout isOpen={!openSessionTimeout} onContinue={handleContinueSession} />
          <SkipNavContent />
          <main id="appMain">{renderRoutes(routeConfig)}</main>
          {showFooter && <Footer />}
        </div>
        {(!isEnableCopilotForUserFlag && isCopilotVisibleForUnauthFlag) || (isEnableCopilotForUserFlag && isCopilotVisible) ? (
          <CopilotBot
            isCopilotVisible={isCopilotVisible}
            changeCopilotVisibility={changeCopilotVisibility}
            isUserLoggedIn={!isEnableCopilotForUserFlag}
          />
        ) : null}
      </div>
    </div>
  );
};

App.defaultProps = {
  showHeader: true,
  showFooter: true,
  showPersona: true
} as IAppProps;

export default React.memo(App);
