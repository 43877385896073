import { FontIcon, Link } from '@fluentui/react';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';

import { locales } from '../../locales';
import { useLocale } from '../LocalizationProvider/LocalizationProvider';
import './Footer.scss';
import ConditionalRenderer from '../../pages/ConditionalRenderer/ConditionalRenderer';
import { Helper } from '../../utilities';
import { IApplicationState } from '../../store';

/**
 * Footer component
 */

export const Footer: React.FunctionComponent = () => {
  const year = new Date().getFullYear();
  const intl = useIntl();
  const { locale } = useLocale();
  const localeObject = locales.find(localeObject => localeObject.locale === locale);
  const language = localeObject ? localeObject.displayName : undefined;
  let siteConsent!: WcpConsent.SiteConsent;
  let footerAnalytics = new Cookies().get('footer-Analytics');
  const [profile] = useSelector((state: IApplicationState) => [state.UserStore.profile], shallowEqual);

  const supportLinkLogging = () => {
    Helper.supportLinkVisitLog('Footer', profile.workEmail, profile.id);
  };
  localStorage.getItem('isWcpConsent') != 'false' &&
    WcpConsent &&
    WcpConsent.init(Helper.getLocale(), 'cookie-banner', (err, _siteConsent) => {
      if (err) {
        alert(err);
      } else {
        siteConsent = _siteConsent!;
        footerAnalytics = siteConsent?.isConsentRequired;
      }
    });

  const _managecookie = () => {
    // allow for Clarity
    WcpConsent &&
      WcpConsent.init(Helper.getLocale(), 'cookie-banner', (err, _siteConsent) => {
        if (err) {
          alert(err);
        } else {
          siteConsent = _siteConsent!;
          if (footerAnalytics) {
            setTimeout(() => {
              const analyticCookies: string[] = ['_clck', '_clsk', 'MUID', 'CLID', 'ANONCHK', 'SM'];
              const cookies = new Cookies();

              analyticCookies.forEach(cookie => {
                cookies.remove(cookie);
              });
              siteConsent.manageConsent();
            }, 30);
          }
        }
      });
  };
  return (
    <footer className="footer" role="contentinfo">
      <div className="linksContainer">
        <NavLink className="footerLocalizationLink" to="/locale">
          <FontIcon className="localizationIcon" iconName="World" />
          <span className="localizationText">{language}</span>
        </NavLink>
        <nav
          className="footerNavigation"
          aria-label={intl.formatMessage({
            defaultMessage: 'Microsoft corporate links'
          })}
        >
          <ul>
            <li className="navItem">
              <Link href="https://go.microsoft.com/fwlink/?linkid=2259814" target="_blank">
                <FormattedMessage defaultMessage="Consumer Health Privacy" />
              </Link>
            </li>
            <li className="navItem">
              <ConditionalRenderer condition={localeObject?.locale === 'en-US'}>
                <Link href="https://www.microsoft.com/en-us/sitemap1.aspx" target="_blank">
                  <FormattedMessage defaultMessage="Sitemap" />
                </Link>
              </ConditionalRenderer>
              <ConditionalRenderer condition={localeObject?.locale === 'ja-JP'}>
                <Link href="https://www.microsoft.com/ja-jp/sitemap.aspx" target="_blank">
                  <FormattedMessage defaultMessage="Sitemap" />
                </Link>
              </ConditionalRenderer>
              <ConditionalRenderer condition={localeObject?.locale === 'ko-KR'}>
                <Link href="https://www.microsoft.com/ko-kr/sitemap.aspx" target="_blank">
                  <FormattedMessage defaultMessage="Sitemap" />
                </Link>
              </ConditionalRenderer>
              <ConditionalRenderer condition={localeObject?.locale === 'zh-CN'}>
                <Link href="https://www.microsoft.com/zh-cn/sitemap.aspx" target="_blank">
                  <FormattedMessage defaultMessage="Sitemap" />
                </Link>
              </ConditionalRenderer>
            </li>
            <li className="navItem">
              <Link href="https://go.microsoft.com/fwlink/?LinkId=521839">
                <FormattedMessage defaultMessage="Privacy & cookies" />
              </Link>
            </li>
            {footerAnalytics && (
              <li className="navItem">
                <Link onClick={_managecookie} href="#" target="" id="manage-cookie" className="">
                  <FormattedMessage defaultMessage="Manage cookies" />
                </Link>
              </li>
            )}
            <li className="navItem">
              <Link href="https://go.microsoft.com/fwlink/?LinkID=206977">
                <FormattedMessage defaultMessage="Terms of use" />
              </Link>
            </li>
            <li className="navItem">
              <Link href="https://go.microsoft.com/fwlink/?linkid=2196228">
                <FormattedMessage defaultMessage="Trademarks" />
              </Link>
            </li>
            <li className="navItem">
              <Link target="_blank" href={process.env.REACT_APP_SUPPORT_EMAIL_LINK + locale} onClick={supportLinkLogging}>
                <FormattedMessage defaultMessage="Contact ESI Support" />
              </Link>
            </li>
          </ul>
          <span>
            &#169; <FormattedMessage defaultMessage="Microsoft" /> {year}
          </span>
        </nav>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
