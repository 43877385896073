import { put, takeLeading } from 'redux-saga/effects';

import { IAccountUnlinkRequest, ILinkedAccount, IUnlinkedAccountResult } from '../../../models';
import Api from '../../api/Api';
import { EndpointSaga, IEndpointSagaDictionary } from '../../endpoint';
import { GetLinkedAccountsActions, LinkAccountActions, UnlinkAccountActions } from './actions';

/**
 * Custom EndpointSaga class for Account Linking which overrides the success hook
 *  to request the user's linked accounts after successfully linking a new account
 *
 * @augments {EndpointSaga<string, {}>}
 */
class LinkAccountEndpointSaga extends EndpointSaga<string, {}> {
  /** Fetches the users linked accounts after successfully linking a new account */
  protected *onSuccessExecuted(): IterableIterator<any> {
    yield put(GetLinkedAccountsActions.Execute());
  }
}

export const sagas: IEndpointSagaDictionary = {
  LinkAccountsSaga: new LinkAccountEndpointSaga(LinkAccountActions, Api.LINK_ACCOUNTS, takeLeading),
  UnlinkAccountsSaga: new EndpointSaga<IAccountUnlinkRequest, IUnlinkedAccountResult>(
    UnlinkAccountActions,
    Api.UNLINK_ACCOUNTS,
    takeLeading
  ),
  GetLinkedAccountsSaga: new EndpointSaga<string, ILinkedAccount[]>(GetLinkedAccountsActions, Api.GET_LINKED_ACCOUNTS, takeLeading)
};
