import { AnyAction, Reducer } from 'redux';

import { ICustomErrorMessage, ILinkedAccount } from '../../../models';
import { ErrorMessageMapper } from '../../../utilities';
import { EndpointStateFactory, EndpointStore, IEndpointState } from '../../endpoint';
import { GetLinkedAccountsActions, UnlinkAccountActions, LinkAccountActions } from './actions';
import { sagas } from './sagas';

export * from './actions';

/** The name of the state, this will be the Redux property that data is stored under */
const storeName = 'AccountLinkingStore';

export interface IAccountLinkingState extends IEndpointState {
  /** The user's linked accounts state */
  readonly linkedAccounts: ILinkedAccount[];
  readonly customErrorMessage: ICustomErrorMessage | null;
}

/** IState acts only as a helper type to make store logic easier to copy and paste */
type IState = IAccountLinkingState;

/**
 * The initial state of the endpoint store. Endpoint stores have base states (ex: isFetching, isFetched, etc.).
 *  We use the EndointStateFactory to make sure to populate these values with the correct defaults
 */
export const initialAccountLinkingState: IState = new EndpointStateFactory<IState>().getInitialState({
  linkedAccounts: [],
  customErrorMessage: null
});

/**
 * Reducer responsible for managing the slice of state that belongs to the store
 *
 * @param state - Initial account linking state
 * @param action - The action
 */
const reducer: Reducer<IState> = (state: IState = initialAccountLinkingState, action: AnyAction): IState => {
  switch (action.type) {
    case GetLinkedAccountsActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        linkedAccounts: action.payload
      };
    case GetLinkedAccountsActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case LinkAccountActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case UnlinkAccountActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        linkedAccounts: action.payload.linkedAccounts
      };
    case UnlinkAccountActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    default:
      return state;
  }
};

/** Responsible for handling the related state in the Redux store */
export const AccountLinkingStore = new EndpointStore<IState>(storeName, initialAccountLinkingState, reducer, sagas, [
  GetLinkedAccountsActions,
  UnlinkAccountActions
]);
