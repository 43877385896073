import { AnyAction, Reducer, ReducersMapObject } from 'redux';

/**
 * A registry that allows you to dynamically add reducers and notify a listener
 */
class ReducerRegistry {
  /** A listener to notify when changes occur to the reducer list */
  private _emitChange: any;

  /** A map of all registered reducers */
  private _reducers: ReducersMapObject;

  constructor() {
    this._reducers = {};
  }

  /** Get the current map of all reducers */
  public getReducers(): ReducersMapObject {
    return this._reducers;
  }

  /**
   * Register a new reducer
   *
   * @param {string} name The name of the reducer
   * @param reducer The reducer object
   */
  public register<State>(name: string, reducer: Reducer<State, AnyAction>): void {
    this._reducers = { ...this._reducers, [name]: reducer };
    if (this._emitChange) {
      this._emitChange(this.getReducers());
    }
  }

  /**
   * Set the listener to notify when there are changes to the list of reducers
   *
   * @param listener - The listener
   */
  public setChangeListener(listener: any): void {
    this._emitChange = listener;
    this._emitChange(this.getReducers());
  }
}

export const reducerRegistry = new ReducerRegistry();
