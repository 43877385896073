import React from 'react';

import App, { IAppProps } from '../../components/App/App';
import { appSettings } from '../../Settings';

const LocalizedApp: React.FunctionComponent<IAppProps> = props => {
  const account = appSettings.config.msal.getAllAccounts()[0];
  const isAuthenticated = !!account;
  // Get customer authentication information
  return isAuthenticated ? <App showHeader={true} showPersona={true} /> : <App showHeader={true} showPersona={false} />;
};

export default React.memo(LocalizedApp);
