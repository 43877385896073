import { AnyAction, Reducer } from 'redux';

import { IRequestResponse, IDeliveryInput, IDeliveryDesc, ICustomErrorMessage } from '../../../models';
import { ErrorMessageMapper } from '../../../utilities';
import { EndpointStateFactory, EndpointStore, IEndpointState } from '../../endpoint';
import { DeliveryDescActions } from './actions';
import { sagas } from './sagas';
export * from './actions';

/** The name of the state, this will be the Redux property that data is stored under */
const storeName = 'DeliveryStore';

export interface IDeliverySate extends IEndpointState {
  /** The user profile state */
  readonly deliveryinput: IDeliveryInput;
  readonly deliverydesc: IDeliveryDesc[];
  readonly response: IRequestResponse<any>;
  readonly customErrorMessage: ICustomErrorMessage | null;
}

/** IState acts only as a helper type to make store logic easier to copy and paste */
type IState = IDeliverySate;

/**
 * The initial state of the endpoint store. Endpoint stores have base states (ex: isFetching, isFetched, etc.).
 *  We use the EndointStateFactory to make sure to populate these values with the correct defaults
 */
export const initialDeliveryState: IState = new EndpointStateFactory<IState>().getInitialState({
  deliverydesc: [],
  customErrorMessage: null
});

/**
 * Reducer responsible for managing the slice of state that belongs to the store
 *
 * @param state - Initial user state
 * @param action - The action
 */

const reducer: Reducer<IState> = (state: IState = initialDeliveryState, action: AnyAction): IState => {
  switch (action.type) {
    case DeliveryDescActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        deliverydesc: action.payload
      };
    case DeliveryDescActions.ActionTypes.ExecuteError:
      if (action.meta) {
        return {
          ...state,
          deliverydesc: action.meta.data,
          isError: true,
          customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

/** Responsible for handling the related state in the Redux store */
export const DeliveryStore = new EndpointStore<IState>(storeName, initialDeliveryState, reducer, sagas, [DeliveryDescActions]);
