import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';

import { IUser } from '../models';
import { appSettings } from '../Settings';

export const useAuthenticatedUser = (): IUser | null => {
  const { inProgress } = useMsal();
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    const account = appSettings.config.msal.getAllAccounts()[0];
    const claims = account?.idTokenClaims || ({} as any);
    const isAuthenticated = !!account;
    const isAuthenticationInProgress = inProgress !== InteractionStatus.None;
    setUser({
      homeAccountId: account?.homeAccountId || '',
      environment: account?.environment || '',
      tenantId: account?.tenantId || '',
      username: account?.username || '',
      localAccountId: account?.localAccountId || '',
      name: account?.name || '',
      firstName: claims['given_name'] || '',
      lastName: claims['family_name'] || '',
      oid: claims['oid'] || '',
      tid: claims['tid'] || '',
      authenticationState: isAuthenticated,
      isAuthenticationInProgress: isAuthenticationInProgress
    });
  }, [inProgress]);

  return user;
};
