import { AnyAction, Reducer } from 'redux';

import { EndpointActions, EndpointReductor, IEndpointSagaDictionary, IEndpointState, reducerRegistry, sagaRegistry } from './';

export interface IStore<State> {
  /** Represents the initial state of the store that extends this class */
  readonly initialState: State;

  /** The sagas that handles the actions for the store */
  readonly sagas?: IEndpointSagaDictionary;

  /** The reducer responsible for handling state */
  reducer: Reducer<State>;
}

/** Represents a contract for all stores that manage data from an endpoint */
export class EndpointStore<State extends IEndpointState> implements IStore<State> {
  /** A collection of all the names given to the instantiated classes, used for validation of name uniqueness */
  private static _namesList: string[] = [];

  /** Represents the initial state of the store that extends this class */
  public readonly initialState: State;

  /** The root saga that handles the actions for the store */
  public readonly sagas: IEndpointSagaDictionary;

  /**
   * The reductor will augment the state with useful flags (loading, errors, etc) commonly related to handling Api endpoints.
   * These defaults for each action type can be overriden in the reducer.
   */
  private _reductor: EndpointReductor<State> | null;

  /** The reducer responsible for handling state */
  private readonly _reducer: Reducer<State>;

  constructor(
    name: string,
    initialState: State,
    reducer: Reducer<State>,
    sagas: IEndpointSagaDictionary,
    endpointActions?: Array<EndpointActions<any, any>>
  ) {
    if (!name) {
      throw Error('EndpointStore names must be a valid string.');
    }

    if (EndpointStore._namesList.includes(name)) {
      throw Error(`EndpointStore names must be unique. The name "${name}" already exists.`);
    } else {
      EndpointStore._namesList.push(name);
    }

    this.initialState = initialState;
    this._reducer = reducer;
    this.sagas = sagas;

    if (endpointActions && endpointActions.length) {
      this._reductor = new EndpointReductor<State>(initialState, endpointActions);
    } else {
      this._reductor = null;
    }

    reducerRegistry.register<State>(name, this.reducer);
    sagaRegistry.registerAnthology(name, this.sagas);
  }

  /**
   * A reducer that is chained with a base reducer to first process all actions through an endpoint reducer
   *
   * @param state - Initial state
   * @param action - The action
   */
  public reducer: Reducer<State> = (state = this.initialState, action: AnyAction): State => {
    if (this._reductor) {
      state = this._reductor.reduce(state, action);
    }

    return this._reducer(state, action);
  };
}
