import { IDeleteDuplicateProfileRequest, IDeleteDuplicateProfileResponse } from '../../../models/deleteDuplicateProfileRequest';
import { IMultipleProfileRequest, IMultipleProfileResponse } from '../../../models/multipleProfileRequest';
import { IUpdateAccountRequest, IUpdateAccountRequestResponse } from '../../../models/UpdateAccountRequest';
import { EndpointActions } from '../../endpoint';

export const GetUpdateAccountWorkEmailOnlyActions = new EndpointActions<IUpdateAccountRequest[], IUpdateAccountRequestResponse[]>(
  'UpdateAccountWorkEmailOnly'
);
export const GetUpdateAccountActions = new EndpointActions<IUpdateAccountRequest[], IUpdateAccountRequestResponse[]>('UpdateAccount');
export const GetUpdateAccountOidEmailAddressOnlyActions = new EndpointActions<IUpdateAccountRequest[], IUpdateAccountRequestResponse[]>(
  'UpdateAccountOidEmailAddressOnly'
);
export const GetMultipleProfiles = new EndpointActions<IMultipleProfileRequest[], IMultipleProfileResponse[]>('multipleProfiles');
export const DeleteDuplicateProfile = new EndpointActions<IDeleteDuplicateProfileRequest[], IDeleteDuplicateProfileResponse[]>(
  'deleteDuplicateProfile'
);
export const OfferTransHistoryActions = new EndpointActions<string, any>('OfferTransaction');
