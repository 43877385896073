import { PrimaryButton } from '@fluentui/react';
import { DefaultButton, Dialog, DialogType, FontIcon } from 'office-ui-fabric-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import FeatureFlagValues from '../../utilities/featureFlagValues';
import { useLogout } from '../../hooks';
import { sessionExpireCountdownTime } from '../../utilities/Constants';

import './SessionTimeout.scss';

interface IComponentProps {
  onContinue?: any;
  isOpen: boolean;
  onDismiss?: () => void;
  subText?: string;
}

const SessionTimeout: React.FunctionComponent<IComponentProps> = props => {
  const { isOpen, subText, onContinue } = props;
  const intl = useIntl();
  const signout = useLogout();
  const hideSessionTimeout = FeatureFlagValues()?.hideSessionTimeout;

  const staySignedIn = () => {
    handleContinueSession(true);
    setTime([sessionExpireCountdownTime]);
  };

  const dialogContentProps = {
    type: DialogType.normal,
    closeButtonAriaLabel: 'Close',
    showCloseButton: false,
    subText: subText
  };

  const modalProps = React.useMemo(
    () => ({
      isBlocking: true,
      containerClassName: 'dialogContent'
    }),
    []
  );

  const [[s], setTime] = React.useState([sessionExpireCountdownTime]);

  const tick = () => {
    if (s === 0) {
      handleContinueSession(false);
    } else {
      setTime([s - 1]);
    }
  };

  const handleContinueSession = (showSessionExpired: boolean) => {
    if (showSessionExpired === false && !hideSessionTimeout) {
      localStorage.setItem('isSessionExpired', 'yes');
      signout();
    } else {
      localStorage.setItem('isSessionExpired', 'no');
      onContinue();
    }
  };

  React.useEffect(() => {
    if (!isOpen && !hideSessionTimeout) {
      const timerID = setInterval(() => tick(), 1000);
      return () => clearInterval(timerID);
    }
  });

  return (
    <>
      {!hideSessionTimeout && (
        <Dialog
          className="SessionTimeout"
          hidden={isOpen}
          onDismiss={signout}
          dialogContentProps={dialogContentProps}
          modalProps={modalProps}
        >
          <div className="requestInformationDialogContent">
            <div className="content">
              <FontIcon className="stopWatch" iconName="Stopwatch" />
              <h3 className="title">
                <span className="contentBack ms-fontSize-28 ms-fontWeight-semibold">
                  <FormattedMessage
                    defaultMessage="Your session is about to end in {count} seconds."
                    values={{
                      count: `${s.toString().padStart(2, '0')}`
                    }}
                  />
                </span>
              </h3>
            </div>
            <div className="divider" />
            <div className="contentDescription">
              <FormattedMessage
                defaultMessage="You’ve been inactive for a while. For your security purpose we’ll be automatically sign you out in approximately 30 seconds. You may choose
“Stay signed in” to continue or “sign out” if you’re done."
              />
            </div>

            <div className="ms-Grid requestInformationActionsButton">
              <div className="ms-Grid-row requestInformationActionsWrapper">
                <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg3">
                  <DefaultButton
                    data-testid="btn-signout"
                    ariaLabel="Sign out"
                    className="cancelButton"
                    onClick={signout}
                    text={intl.formatMessage({ defaultMessage: 'Sign out' })}
                  />
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg3">
                  <PrimaryButton
                    data-testid="btn-staySignedIn"
                    className="submitButton"
                    type="submit"
                    onClick={staySignedIn}
                    text={intl.formatMessage({ defaultMessage: 'Stay signed in' })}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default React.memo(SessionTimeout);
