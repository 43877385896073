import Axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

import { Helper } from '../../utilities';
import { EndpointConfiguration } from '../endpoint';
import { TokenHandler } from '../../utilities/TokenHandler';
import { silentRequest } from '../../Settings';
import { useAuthority } from '../../hooks';

// Configure an instance of Axios for web requests
const http = Axios.create({
  baseURL: '/'
});

export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH'
}

/**
 * Class to handle calls to server which becomes a base for any API classes
 */
export default class BaseApi {
  /**
   * Asynchronously execute a request
   *
   * @param {AxiosRequestConfig} config configuration of the web request
   */
  public static async REQUEST(config: AxiosRequestConfig): Promise<any> {
    return http(config);
  }

  /**
   * Takes configuration settings and determines how to execute the web request
   *
   * Takes configuration settings and determines how to execute the web request
   *
   * @param {EndpointConfiguration} endpoint The configuration for the service being called
   * @param {AxiosRequestConfig} config The configuration properties of the web request
   * @param isAnonymous If the call is Anonmous
   */
  public static async REQUEST_WITH_CONFIG(endpoint: EndpointConfiguration, config: AxiosRequestConfig, isAnonymous = false): Promise<any> {
    let combinedHeaders: AxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': endpoint.config.service.apiKey
    };

    if (!isAnonymous) {
      const request = { ...silentRequest };
      request.authority = useAuthority();
      const response = await TokenHandler.getToken(request);

      const accessToken = response.accessToken;
      if (accessToken) {
        combinedHeaders.Authorization = `Bearer ${accessToken}`;
        combinedHeaders.Locale = Helper.getLocale();
      }
    }

    combinedHeaders = {
      ...config.headers,
      ...combinedHeaders
    };

    return BaseApi.REQUEST({ ...config, url: endpoint.getEndpointUrl(), headers: combinedHeaders });
  }
}
