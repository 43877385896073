import { useEffect, useState } from 'react';

import { silentRequest } from '../Settings';
import { TokenHandler } from '../utilities/TokenHandler';
import { useAuthority } from './useAuthority';
import { ai } from '../components/ApplicationInsightsProvider/ApplicationInsightsService';

export const useAccessToken = (isUserLoggedIn?: boolean) => {
  const authority = useAuthority();
  const [accessToken, setAccessToken] = useState<string>('');
  const [timeOutDuration, setTimeOutDuration] = useState<number>(0);

  useEffect(() => {
    const request = { ...silentRequest, authority };

    /**
     *
     */
    async function fetchData() {
      const response = await TokenHandler.getToken(request);
      ai.appInsights?.trackEvent({
        name: 'LXP.UseAccessToken',
        properties: { expiresOn: response?.expiresOn?.getTime() }
      });
      if (response?.expiresOn) {
        const oneMinuteBeforeExpires: number = response?.expiresOn.getTime() - 60000; // Subtract 1 minute (60000 milliseconds)
        setTimeOutDuration(oneMinuteBeforeExpires - Date.now());
      }
      setAccessToken(response.accessToken);
    }
    const intervalId = setTimeout(() => {
      if (!isUserLoggedIn) {
        fetchData();
      }
    }, timeOutDuration);

    return () => clearTimeout(intervalId); // Cleanup interval on unmount
  }, [authority, timeOutDuration, isUserLoggedIn]);

  return accessToken;
};
