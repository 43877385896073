import { useIntl } from 'react-intl';

import { ICustomErrorMessage, IRequestResponse } from '../models';
import { ai } from '../components/ApplicationInsightsProvider/ApplicationInsightsService';

export class ErrorMessageMapper {
  /**
   * Takes a request reponse and determines the best friendly message to show the user.
   *
   * @param response The error response from the service
   */
  public static GET_ERROR(response?: IRequestResponse<any>): ICustomErrorMessage {
    const defaultMsg = 'An unexpected error occured with your profile.';
    let errorPayload: ICustomErrorMessage = {
      description: defaultMsg
    };
    if (response) {
      const method = response.config.method;
      errorPayload = response.data || errorPayload;
      errorPayload.errorCode = response.data.customError;
      errorPayload.method = method;
      errorPayload.description = response.data.description;
    }

    return errorPayload;
  }

  /**
   * Track in App Insights a service response error that is not yet mapped to a friendly message.
   *
   * @param response The response from the service
   * @param errorCode The custom error code recieved from the service if one was provided
   */
  private static TRACK_UNKNOWN_ERROR(errorCode?: string): void {
    if (ai.appInsights) {
      const code = errorCode || 'no code provided';
      const exception = new Error(`A user encountered an error which had no friendly description (${code}).`);
      exception.name = 'Unknown Error Message';
      ai.appInsights.trackException({ exception });
    } else {
      throw new Error(
        'Unable to track unknown error. Application Insights has not been initialized. Application Insights can only be utilized in children of the ApplicationInsightsProvider component'
      );
    }
  }

  /*
   * Get the localization string and localization id based on error code from service.
   * @param responseErrorCode The response error code from the service
   */
  public static LOCALIZATION_ERRORDESCRIPTION(responseErrorCode?: string, responseDescription?: string): [any?, any?] {
    const intl = useIntl();
    let localizeStringForError = null;
    switch (responseErrorCode) {
      case 'ERR-102':
        localizeStringForError = `${responseDescription} You may also try to clear your browser cache and cookies, close and then start a new browser session to see if this helps.`;
        break;
      case 'ERR-103':
        localizeStringForError = responseDescription;
        break;
      case 'ERR-105':
        localizeStringForError = responseDescription;
        break;
      case 'ERR-111':
        localizeStringForError = responseDescription;
        break;
      case 'ERR-200':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'No email provided'
        });
        break;
      case 'ERR-201':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'No verification code provided'
        });
        break;
      case 'ERR-240':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'Learning Partners not found'
        });
        break;
      case 'ERR-241':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'Delivery Classes Catalog not found'
        });
        break;
      case 'ERR-242':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'Delivery Details not found'
        });
        break;
      case 'ERR-300':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'No id provided'
        });
        break;
      case 'ERR-301':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'Delivery Learner Registration cancellation Status Service Call failure'
        });
        break;
      case 'ERR-331':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'Certificate was not found'
        });
        break;
      case 'ERR-330':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'Enrollment was not found'
        });
        break;
      case 'ERR-340':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'Learning Path not found'
        });
        break;
      case 'ERR-104':
        localizeStringForError = intl.formatMessage({
          defaultMessage:
            'Invalid Login email specified: Its looks like you have used wrong email to sign in to LxP. Please enter the correct email and try again'
        });
        break;
      case 'ERR-520':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'Account linking service failed'
        });
        break;
      case 'ERR-540':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'No learner info'
        });
        break;
      case 'ERR-552':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'Event id or Attendees details unavailable'
        });
        break;
      case 'ERR-553':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'Requested Event Registration Details not found'
        });
        break;
      case 'ERR-554':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'Delivery Learner Registration cancellation Status Service Call failure'
        });
        break;
      case 'ERR-555':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'Delivery Learner Event Registration Status Service Call failure'
        });
        break;
      case 'ERR-556':
        localizeStringForError = responseDescription;
        break;
      case 'ERR-557':
        localizeStringForError = responseDescription;
        break;
      case 'ERR-558':
        localizeStringForError = responseDescription;
        break;
      case 'ERR-622':
        localizeStringForError = intl.formatMessage({
          defaultMessage: 'Certification Failure'
        });
        break;
      case 'ERR-101':
        localizeStringForError = responseDescription;
        break;
      case 'ERR-543':
        localizeStringForError = intl.formatMessage({
          defaultMessage: `The verification code entered is incorrect. Please try again`
        });
        break;
      case 'ERR-521':
        localizeStringForError = intl.formatMessage({
          defaultMessage: `It looks like you have exceeded the maximum number of linked accounts. To sign in to the Learner Experience Portal, select Sign out and then try using one of the accounts that you previously linked`
        });
        break;
      case 'ERR-565':
        localizeStringForError = responseDescription;
        break;
      case 'ERR-566':
        localizeStringForError = responseDescription;
        break;
      default:
        localizeStringForError = intl.formatMessage({
          defaultMessage: `Unknown Error. Please try signing out and signing back into LxP.
          You may also try to clear your browser cache and cookies, close
          and then start a new browser session to see if this helps.`
        });
        ErrorMessageMapper.TRACK_UNKNOWN_ERROR(responseErrorCode);
        break;
    }
    return [localizeStringForError];
  }
}
