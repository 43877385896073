import { Image, Link } from '@fluentui/react';
import React, { useCallback, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { FormattedMessage, useIntl } from 'react-intl';

import { IApplicationState } from '../../store';
import { useEndpointActions } from '../../store/endpoint';
import { GetProfileActions, GetSSOStatusActions } from '../../store/stores/user/actions';
import { ai } from '../ApplicationInsightsProvider/ApplicationInsightsService';
import msLogo from '../../assets/images/microsoft-logo-light.png';
import msLogoContrast from '../../assets/images/MS_logo_Contrast.png';
import { EmailValidationActions } from '../../store/stores/user';
import { disableTermsAndConditions, QueryStringParam, Helper } from '../../utilities';
import { PopupCallout } from '../PopupCallout/PopupCallout';
import Info from '../../assets/images/Info.svg';
import InfoContrast from '../../assets/images/Info_Contrast.svg';
import Profile from '../../assets/images/Profile.svg';
import ProfileContrast from '../../assets/images/Profile_Contrast.svg';
import TaskList from '../../assets/images/Task_List.svg';
import TaskListContrast from '../../assets/images/Task_List_Contrast.svg';
import CopilotButton from './CopilotButton/CopilotButton';
import { ICustomErrorMessage } from '../../models';
import NavbarMenu from './HamburgerMenu/NavbarMenu';
import BackoutBurgerMenu from './HamburgerMenu/BackoutBurgerMenu';
import { useAuthenticatedUser } from '../../hooks/useAuthenticatedUser';
import { GetFeatureFlagAction } from '../../store/stores/featureFlag';
import SearchTextBox from '../GlobalSearch/SearchTextBox';
import { IESITerms } from '../../models/ESITerms';
import { useLocale } from '../../components/LocalizationProvider/LocalizationProvider';

import './Header.scss';

export interface IComponentProps {
  /** showPersona - general info page */
  showPersona: boolean;
  toggleCopilotVisibility: () => void;
  enableCopilotForAccount: boolean;
}

/**
 * Header component
 *
 * @param props - The properties
 */
export const Header: React.FunctionComponent<IComponentProps> = props => {
  const intl = useIntl();
  const { showPersona, enableCopilotForAccount, toggleCopilotVisibility } = props;
  const history = useHistory();
  const account = useAuthenticatedUser();
  const tenantId = account?.tenantId;
  const authenticationState = !!account?.authenticationState;
  const siteName = process.env.REACT_APP_SITE_NAME || '';
  const displayName = account?.firstName || '';
  const userName = account?.username || '';
  const [
    companyName,
    esiIndicator,
    isProfileFetched,
    isProfileExists,
    profileWorkEmailAddress,
    profileEmailAddress,
    isAad,
    authority,
    isMigrateCandidate
  ] = useSelector(
    (state: IApplicationState) => [
      state.UserStore.emailprofile.companyName,
      state.UserStore.emailprofile.ESIIndicator,
      state.UserStore.isFetched,
      state.UserStore.isProfileExists,
      state.UserStore.profile.workEmail,
      state.UserStore.profile.emailAddress,
      state.UserStore.emailprofile.isAad,
      state.UserStore.emailprofile.authority,
      state.UserStore.emailprofile.isMigrateCandidate
    ],
    shallowEqual
  );

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  //const [enableCopilot, setEnableCopilot] = React.useState<boolean>(false);
  const [getProfile] = useEndpointActions([GetProfileActions]);
  const [customerInfo] = useEndpointActions([EmailValidationActions]);
  const userEmail = sessionStorage.getItem(QueryStringParam.WorkEmailAddress);
  const [getFeatureFlag] = useEndpointActions([GetFeatureFlagAction]);
  const eSITerms: IESITerms = useSelector((state: IApplicationState) => state.UserStore.profile.esiTerms);
  const [featureFlags] = useSelector((state: IApplicationState) => [state.FeatureFlagStore?.featureFlags]);
  const [getSSOStatus] = useEndpointActions([GetSSOStatusActions]);
  const { setLocale, setDefaultLocale } = useLocale();

  useEffect(() => {
    if (showPersona) {
      getProfile(userEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPersona]);

  useEffect(() => {
    if (featureFlags.length === 0) {
      getFeatureFlag();
    }
  }, [getFeatureFlag, featureFlags.length]);

  const setInitialLocale = () => {
    const loc = localStorage.getItem('initLocale') ?? localStorage.getItem('locale') ?? 'en-US';
    if (loc !== localStorage.getItem('locale') && loc !== sessionStorage.getItem('locale')) {
      localStorage.setItem('locale', loc);
      sessionStorage.setItem('locale', loc);
      setDefaultLocale(loc);
      setLocale(loc, true);
    }
  };

  useEffect(() => {
    if (isProfileFetched && featureFlags.length > 0) {
      const disableEsiTerms = featureFlags?.filter(d => d?.flagName === disableTermsAndConditions)?.map(m => m?.isEnabledFlag)[0];

      if (!disableEsiTerms) {
        if (eSITerms && eSITerms.isESITermsAccepted) {
          sessionStorage.setItem(QueryStringParam.IsESITermsAccepted, 'yes');
          setInitialLocale();
        } else {
          if (!sessionStorage.getItem(QueryStringParam.IsESITermsAccepted)) {
            const pathName = window.location.pathname.split('/');
            if (
              pathName &&
              pathName[1] &&
              pathName[1] !== '' &&
              pathName[1].toLowerCase() !== 'esiagreement' &&
              pathName[1].toLowerCase() !== 'aadlogin' &&
              pathName[1].toLowerCase() !== 'about' &&
              pathName[1].toLowerCase() !== 'locale'
            ) {
              history.push({
                pathname: '/esiAgreement/',
                state: {
                  pathName: window.location.pathname,
                  search: window.location.search
                }
              });
              return;
            }
          }
        }
      }
    }
  }, [isProfileFetched, eSITerms, history, featureFlags, setInitialLocale]);

  // Get customer information using work email address
  useEffect(() => {
    if (isProfileFetched && profileWorkEmailAddress !== '' && companyName === '') {
      sessionStorage.setItem(QueryStringParam.WorkEmailAddress, profileWorkEmailAddress);
      customerInfo({ emailAddress: profileWorkEmailAddress });
      const userDomain = Helper.getDomain(profileWorkEmailAddress as string);
      if (userDomain != null) {
        getSSOStatus(userDomain);
      }
    }

    if (isAad === true) {
      sessionStorage.setItem(QueryStringParam.IsAad, 'true');
    }
    if (isAad === false) {
      sessionStorage.setItem(QueryStringParam.IsAad, 'false');
    }
    if (authenticationState) {
      sessionStorage.setItem(QueryStringParam.IsAuthenticated, 'true');
    }

    if (authority !== null && authority !== '') {
      sessionStorage.setItem(QueryStringParam.Authority, authority);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileFetched, companyName, authenticationState]);

  // Real edge case where AAD or non-Aad active session exists and prompted to singout.
  useEffect(() => {
    if (isProfileFetched && isProfileExists && esiIndicator && profileEmailAddress && !isMigrateCandidate) {
      if (esiIndicator !== profileEmailAddress) {
        const friendlyError: ICustomErrorMessage = {
          description:
            intl.formatMessage({
              defaultMessage: 'It looks like you already have an active LxP account with'
            }) +
            ' ' +
            esiIndicator +
            '.',
          signOutPrompt: true,
          method: 'UseEffect-ActiveSession',
          page: 'Header'
        };
        if (ai.appInsights) {
          ai.appInsights.trackException({
            exception: new Error('ESIUI'),
            severityLevel: SeverityLevel.Error,
            properties: { friendlyError, isProfileFetched, isProfileExists, esiIndicator, profileEmailAddress, tenantId }
          });
        }
        history.push('/about/', {
          error: friendlyError
        });
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAad,
    isProfileFetched,
    isProfileExists,
    userEmail,
    profileEmailAddress,
    profileWorkEmailAddress,
    esiIndicator,
    history,
    intl,
    isMigrateCandidate
  ]);

  const onSiteNameClick = useCallback(
    e => {
      e.preventDefault();
      history.push('/landing');
    },
    [history]
  );

  return (
    <React.Fragment>
      <div className="container">
        <header role="banner" className="siteHeader ms-depth-16">
          <React.Fragment>
            <div className="headerWrapper" dir="ltr">
              <div className="headerLeft">
                <div className="ms-Logo highContrastModeDisabled">
                  <Link className="logo headerLink" href="https://www.microsoft.com" aria-label="Microsoft">
                    <Image src={msLogo} alt="" aria-hidden={true} height={18} />
                  </Link>
                </div>
                <div className="ms-Logo highContrastModeEnabled">
                  <Link className="logo headerLink " href="https://www.microsoft.com" aria-label="Microsoft">
                    <Image src={msLogoContrast} alt="" aria-hidden={true} height={18} />
                  </Link>
                </div>
                <div className="headerSiteWrapper">
                  <Link id="headerSiteName" className="headerLink" href="/landing" aria-label={siteName} onClick={onSiteNameClick}>
                    <span className="siteName ms-fontWeight-semilight">
                      <FormattedMessage defaultMessage="Enterprise Skills Initiative" />
                    </span>
                  </Link>
                  {companyName && (
                    <React.Fragment>
                      <span className="pipe ms-fontSize-24" />
                      <span className="companyName ms-fontWeight-bold">{companyName}</span>
                    </React.Fragment>
                  )}
                </div>
                <span className="siteNameAcronym ms-fontWeight-light">
                  <Link className="headerLink" href="/landing" aria-label={siteName} tabIndex={-1}>
                    <FormattedMessage defaultMessage="ESI" />
                  </Link>
                </span>
              </div>
              <div className="headerRight">
                {showPersona && (
                  <>
                    <div id="headerRightMenu" className="navPersonaContainer">
                      <PopupCallout displayName={displayName} userName={userName}></PopupCallout>
                      <span className="pipe ms-fontSize-24" />
                      <NavLink className="headerLink" to="/registrations">
                        <Image src={TaskList} alt="" className="imageHeaderBarProfile highContrastModeDisabled" />
                        <Image src={TaskListContrast} alt="" className="imageHeaderBarProfile highContrastModeEnabled" />
                        <span className="headerBar">
                          <FormattedMessage defaultMessage="My Registrations" />
                        </span>
                      </NavLink>
                      <span className="pipe ms-fontSize-24" />
                      <NavLink className="headerLink" to="/settings">
                        <Image src={Profile} alt="" className="imageHeaderBarProfile highContrastModeDisabled" />
                        <Image src={ProfileContrast} alt="" className="imageHeaderBarProfile highContrastModeEnabled" />
                        <span className="headerBar">
                          <FormattedMessage defaultMessage="Settings" />
                        </span>
                      </NavLink>
                      <span className="pipe ms-fontSize-24" />
                      <NavLink className="headerLink" to="/help">
                        <Image src={Info} alt="" className="imageHeaderBarProfile highContrastModeDisabled" />
                        <Image src={InfoContrast} alt="" className="imageHeaderBarProfile highContrastModeEnabled" />
                        <span className="headerBar">
                          <FormattedMessage defaultMessage="Help" />
                        </span>
                      </NavLink>
                      <span className="pipe ms-fontSize-24" />
                      <SearchTextBox defaultUrl="/Search" notGlobal={false}></SearchTextBox>
                    </div>
                    {enableCopilotForAccount && <CopilotButton onClick={toggleCopilotVisibility} />}
                  </>
                )}
                {showPersona && (
                  <div id="hamburgerId" className="hamburgerVisible">
                    <NavbarMenu
                      toggleCopilotVisibility={toggleCopilotVisibility}
                      enableCopilotForAccount={enableCopilotForAccount}
                    ></NavbarMenu>
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
          {showPersona && <BackoutBurgerMenu></BackoutBurgerMenu>}
        </header>
      </div>
    </React.Fragment>
  );
};

export default React.memo(Header);
