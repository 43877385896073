import { ai } from '../components/ApplicationInsightsProvider/ApplicationInsightsService';

export const getVisitLog = (message: string, pageName: string, emailAddress?: string, learnerId?: string) => {
  const date = new Date();
  if (ai.appInsights) {
    ai.appInsights.trackTrace({
      message: message + pageName,
      properties: {
        emailAddress: emailAddress,
        learnerId: learnerId,
        dateOfVisit: date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear(),
        timeOfVisit: date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
      }
    });
  }
};
