import {
  IEventRegistrationRequest,
  IEventRegistrationResponse,
  ICancelRegistrationRequest,
  ICancelRegistrationResponse,
  ICancelWaitlistRegistrationRequest,
  ICancelWaitlistRegistrationResponse,
  IConfirmRegistrationRequest,
  IConfirmRegistrationResponse
} from '../../../models';
import {
  IGetEventSessionsRequest,
  IGetEventSessionsResponse,
  IPatchEventSessionsRequest,
  IPatchEventSessionsResponse
} from '../../../models/EventManagement/EventSessions';
import { IPostResendInviteRequest, IPostResendInviteResponse } from '../../../models/EventManagement/PostResendInvite';
import { EndpointActions } from '../../endpoint';

export const GetCreateRegistrationActions = new EndpointActions<IEventRegistrationRequest, IEventRegistrationResponse>('EventRegistration');
export const GetCancelRegistrationActions = new EndpointActions<ICancelRegistrationRequest, ICancelRegistrationResponse>(
  'CancelRegistration'
);
export const GetCancelWaitlistActions = new EndpointActions<ICancelWaitlistRegistrationRequest, ICancelWaitlistRegistrationResponse>(
  'CancelWaitlist'
);
export const GetConfirmRegistrationActions = new EndpointActions<IConfirmRegistrationRequest, IConfirmRegistrationResponse>(
  'ConfirmRegistration'
);

export const GetEventSessionsActions = new EndpointActions<IGetEventSessionsRequest, IGetEventSessionsResponse>('GetEventSessions');

export const PatchEventSessionsActions = new EndpointActions<IPatchEventSessionsRequest[], IPatchEventSessionsResponse>(
  'PatchEventSessions'
);
export const PostResendInviteActions = new EndpointActions<IPostResendInviteRequest, IPostResendInviteResponse>('PostResendInvite');
