import { takeLatest, takeLeading } from 'redux-saga/effects';

import {
  ILanguage,
  ITimezone,
  IRole,
  IModality,
  ICountry,
  INotifications,
  IESIAgreement,
  IVerificationEmailRequest
} from '../../../models';
import { IMeasureUpLTISettings } from '../../../models/MeasureUpLTISettings';
import Api from '../../api/Api';
import { EndpointSaga, IEndpointSagaDictionary } from '../../endpoint';
import {
  GetLanguagesActions,
  GetTimeZonesActions,
  GetModalitiesActions,
  GetRolesActions,
  GetNotificationsActions,
  GetCountryActions,
  GetMeasureUpLTISettingsActions,
  GetESIAgreementActions,
  GetEmailCodeVerificationStatusActions
} from './actions';

export const sagas: IEndpointSagaDictionary = {
  GetLanguagesSaga: new EndpointSaga<string, ILanguage[]>(GetLanguagesActions, Api.GET_LANGUAGES, takeLeading),
  GetNotificationsSaga: new EndpointSaga<string, INotifications[]>(GetNotificationsActions, Api.GET_NOTIFICATIONS, takeLeading),
  GetESIAgreementSaga: new EndpointSaga<string, IESIAgreement>(GetESIAgreementActions, Api.GET_ESIAgreement, takeLeading),
  GetTimezonesSaga: new EndpointSaga<string, ITimezone[]>(GetTimeZonesActions, Api.GET_TIMEZONES, takeLeading),
  GetModalitiesSaga: new EndpointSaga<string, IModality[]>(GetModalitiesActions, Api.GET_MODALITIES, takeLeading),
  GetRolesSaga: new EndpointSaga<string, IRole[]>(GetRolesActions, Api.GET_ROLES, takeLeading),
  GetCountrySaga: new EndpointSaga<string, ICountry[]>(GetCountryActions, Api.GET_COUNTRY, takeLeading),
  GetMeasureUpLTISettingsSaga: new EndpointSaga<string, IMeasureUpLTISettings[]>(
    GetMeasureUpLTISettingsActions,
    Api.GET_MEASUREUP_LTI_SETTINGS,
    takeLeading
  ),
  GetEmailCodeVerificationStatus: new EndpointSaga<IVerificationEmailRequest, boolean>(
    GetEmailCodeVerificationStatusActions,
    Api.EMAILCODE_VERIFICATION_STATUS,
    takeLatest
  )
};
