import { AnyAction, Reducer } from 'redux';

import { ICustomErrorMessage } from '../../../models';
import { IRequestAdditionalTokensRequest, IRequestAdditionalTokensResponse } from '../../../models/RequestAdditionalTokens';
import { ErrorMessageMapper } from '../../../utilities';
import { EndpointStateFactory, EndpointStore, IEndpointState } from '../../endpoint';
import { SendRequestForAdditonalTokensActions } from './actions';
import { sagas } from './sagas';
export * from './actions';

const storeName = 'RequestAdditionalTokensStore';

export interface IRequestAdditionalTokensState extends IEndpointState {
  /** The account update request here */
  readonly requestAdditionalTokensRequest: IRequestAdditionalTokensRequest;
  readonly requestAdditionalTokensResponse: IRequestAdditionalTokensResponse;
  readonly customErrorMessage: ICustomErrorMessage | null;
  readonly isFormSubmitted: boolean;
}

type IState = IRequestAdditionalTokensState;

export const initialRequestAdditionalTokensState: IState = new EndpointStateFactory<IState>().getInitialState({
  requestAdditionalTokensRequest: {
    LxpPuid: '',
    FirstName: '',
    LastName: '',
    EmailAddress: '',
    Organization: '',
    BusinessJustification: ''
  },
  requestAdditionalTokensResponse: {
    resultMessage: ''
  },
  customErrorMessage: null
});

/**
 * Reducer responsible for managing the slice of state that belongs to the store
 *
 * @param state - Initial request additional tokens state
 * @param action - The action
 */
const reducer: Reducer<IState> = (state: IState = initialRequestAdditionalTokensState, action: AnyAction): IState => {
  switch (action.type) {
    case SendRequestForAdditonalTokensActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        isFormSubmitted: true,
        requestAdditionalTokensResponse: action.payload
      };
    case SendRequestForAdditonalTokensActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isFormSubmitted: false,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    default:
      return state;
  }
};

/** Responsible for handling the related state in the Redux store */
export const RequestAdditonalTokensStore = new EndpointStore<IState>(storeName, initialRequestAdditionalTokensState, reducer, sagas, [
  SendRequestForAdditonalTokensActions
]);
