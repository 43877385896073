// Include polyfills necessary for the dependencies of the starter kit
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { Fabric } from 'office-ui-fabric-react';
import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import AuthenticatedApp from './components/App/AuthenticatedApp';
import { UnauthenticatedApp } from './components/App/UnauthenticatedApp';
import ApplicationInsightsProvider from './components/ApplicationInsightsProvider/ApplicationInsightsProvider';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import { initializeIcons } from './utilities/fabric-icons/src';
import iconSubset from './assets/fonts/fabric-icons/fabric-icons-5692869f.woff';
import { locales, loadLocaleData } from './locales';
import { LocalizationProvider } from './components/LocalizationProvider/LocalizationProvider';
import 'office-ui-fabric-react/dist/css/fabric.min.css';
import './index.scss';
import { appSettings } from './Settings';
import LocalizedApp from './components/App/LocalizedApp';
import './wcpConsent';

initializeIcons(iconSubset);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60,
      retry: false
    }
  }
});

const locale: string = localStorage.getItem('locale') ?? 'en-US';

const renderContent = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <Fabric>
          <MsalProvider instance={appSettings.config.msal}>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <BrowserRouter>
                <ApplicationInsightsProvider instrumentationKey={process.env.REACT_APP_APPLICATION_INSIGHTS_KEY!}>
                  <LocalizationProvider
                    locale={locale}
                    defaultLocale={locale}
                    locales={locales}
                    storage={localStorage}
                    localeLoader={loadLocaleData}
                  >
                    <Switch>
                      <Route path="/accountlinking/" component={AuthenticatedApp} />
                      <Route
                        path="/onboarding/"
                        component={UnauthenticatedApp({ showHeader: false, showFooter: false, showPersona: false })}
                      />
                      <Route path="/register/" component={UnauthenticatedApp({ showHeader: false })} />
                      <Route path="/about/" component={UnauthenticatedApp({ showPersona: false })} />
                      <Route path="/AadLogin/" component={UnauthenticatedApp({ showHeader: true, showPersona: false })} />
                      <Route path="/esiAgreement/" component={UnauthenticatedApp({ showHeader: true, showPersona: false })} />
                      <Route
                        path="/emailvalidation/"
                        component={UnauthenticatedApp({ showHeader: false, showFooter: false, showPersona: false })}
                      />
                      <Route path="/Delivery/" component={AuthenticatedApp} />
                      {/* Commented out the below path to stop routing to Cloud games*/}
                      {/* <Route path="/gamelearning/" component={AuthenticatedApp} /> */}
                      <Route path="/deliverymultiday/" component={AuthenticatedApp} />
                      <Route path="/deliveryonly/" component={AuthenticatedApp} />
                      <Route path="/landing/" component={AuthenticatedApp} />
                      <Route path="/home/" component={AuthenticatedApp} />
                      <Route path="/findmoreclasses/" component={AuthenticatedApp} />
                      <Route path="/getcertification/" component={AuthenticatedApp} />
                      <Route path="/settings/" component={AuthenticatedApp} />
                      <Route path="/help/" component={AuthenticatedApp} />
                      <Route path="/locale/" component={LocalizedApp} />
                      <Route path="/registrations/" component={AuthenticatedApp} />
                      <Route path="/coursedetails/" component={AuthenticatedApp} />
                      <Route path="/beprepared/" component={AuthenticatedApp} />
                      <Route path="/buildconfidence/" component={AuthenticatedApp} />
                      <Route path="/celebrateskills/" component={AuthenticatedApp} />
                      <Route path="/reviewpath/" component={AuthenticatedApp} />
                      <Route path="/support/" component={AuthenticatedApp} />
                      <Route path="/search/" component={AuthenticatedApp} />
                      <Route path="/" component={UnauthenticatedApp({ showHeader: false })} />
                    </Switch>
                  </LocalizationProvider>
                </ApplicationInsightsProvider>
              </BrowserRouter>
            </QueryClientProvider>
          </MsalProvider>
        </Fabric>
      </Provider>
    </React.StrictMode>
  );
};

render(renderContent(), document.getElementById('root') as HTMLElement);

// Register the service worker
serviceWorker.unregister();
