import {
  InteractionStatus,
  RedirectRequest,
  SilentRequest,
  InteractionRequiredAuthError,
  IPublicClientApplication
} from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { History, Location } from 'history';
import { useSelector, shallowEqual } from 'react-redux';

import { IApplicationState } from '../store';
import { Helper, QueryStringParam } from '../utilities';
import { appSettings, redirectRequestSelectAccount } from '../Settings';
import { useAuthority } from './useAuthority';
import { useEndpointActions } from '../store/endpoint';
import { ai } from '../components/ApplicationInsightsProvider/ApplicationInsightsService';
import { ICustomErrorMessage } from '../models';
import FeatureFlagValues from '../utilities/featureFlagValues';
import { GetFeatureFlagAction } from '../store/stores/featureFlag/actions';

const enabledHosts = new Set((process.env.REACT_APP_SSO_ENABLED_HOSTS ?? '').split(',').filter(item => item !== ''));

const shouldRemoveSSO = (location: Location): boolean => {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get('sso') != null && !validReferrer();
};

const removeSSOParams = (location: Location, history: History) => {
  const searchParams = new URLSearchParams(location.search);
  if (searchParams.get('context') == null && searchParams.get('source') == null && searchParams.get('sso') == null) {
    return;
  }
  searchParams.delete('context');
  searchParams.delete('source');
  searchParams.delete('sso');
  history.replace(`${location.pathname}?${searchParams.toString()}`);
};

const validReferrer = (): boolean => {
  if (document.referrer.trim() === '') {
    return false;
  }
  const referrer = new URL(document.referrer).host;
  for (const domain of enabledHosts) {
    if (referrer.endsWith(`.${domain}`) || referrer === domain) {
      sessionStorage.setItem(QueryStringParam.SSOReferrer, document.referrer);
      return true;
    }
  }
  return false;
};

const shouldAttemptSSO = (location: Location): boolean => {
  const searchParams = new URLSearchParams(location.search);
  const sso = searchParams.get('sso');
  return sso != null && validReferrer();
};

const ssoActive = (location: Location): boolean => {
  const searchParams = new URLSearchParams(location.search);
  const hasSSO = searchParams.get('sso') != null;
  const hasSSOContext = searchParams.get('context') === 'sso';
  return (hasSSO || hasSSOContext) && validReferrer();
};

const updateSSOParams = (location: Location, history: History) => {
  const searchParams = new URLSearchParams(location.search);
  const ssoSource = searchParams.get('sso') ?? 'unknown';
  if (searchParams.get('context') === 'sso' && searchParams.get('source') === ssoSource && searchParams.get('sso') == null) {
    return;
  }

  searchParams.set('context', 'sso');
  searchParams.set('source', ssoSource);
  searchParams.delete('sso');
  history.replace(`${location.pathname}?${searchParams.toString()}`);
  Helper.setClarityCustomTag('SSOSignIn', ssoSource);
  Helper.setClarityCustomTag('EventSSOSignIn', 'SSOSignedIn');
};

const msalSSO = async (msal: IPublicClientApplication, history: History) => {
  try {
    await msal.ssoSilent({
      scopes: appSettings.config.defaultAuthScopes,
      redirectUri: `${window.location.origin}/auth.html`
    });
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await msal.logoutRedirect({ onRedirectNavigate: _ => false });
      await msal.acquireTokenRedirect(redirectRequestSelectAccount);
    } else {
      history.push('/');
    }
  }
};

const useDisableLxpSSO = () => {
  const [getFeatureFlag] = useEndpointActions([GetFeatureFlagAction]);
  const [flagFetching, flagsFetched] = useSelector(
    (state: IApplicationState) => [state.FeatureFlagStore.isFetching, state.FeatureFlagStore.isFetched],
    shallowEqual
  );
  const { disableLxpSSO } = FeatureFlagValues();

  useEffect(() => {
    if (!flagFetching && !flagsFetched) {
      getFeatureFlag();
    }
  }, [flagFetching, flagsFetched, getFeatureFlag]);

  return { disableLxpSSO, loading: disableLxpSSO == null };
};

export const useAuthenticator = () => {
  const friendlyError: ICustomErrorMessage = { description: '' };
  const [error, seterror] = useState(friendlyError);
  const intl = useIntl();
  const history = useHistory();
  const authorityurl = useAuthority();
  const location = useLocation();
  const msal = appSettings.config.msal;
  const request: SilentRequest = {
    scopes: appSettings.config.defaultAuthScopes,
    authority: authorityurl,
    account: msal.getAllAccounts()[0]
  };
  const isAuthenticated = useIsAuthenticated();
  const { disableLxpSSO, loading: pendingDisableLxpSSO } = useDisableLxpSSO();
  const context = useMsal();

  useEffect(() => {
    if (isAuthenticated && !disableLxpSSO && !pendingDisableLxpSSO && shouldAttemptSSO(location)) {
      updateSSOParams(location, history);
    }
    if ((shouldRemoveSSO(location) || disableLxpSSO) && !pendingDisableLxpSSO) {
      removeSSOParams(location, history);
    }
    if (!isAuthenticated && context.inProgress === InteractionStatus.None) {
      if (ssoActive(location) || pendingDisableLxpSSO) {
        if (shouldAttemptSSO(location) && !disableLxpSSO && !pendingDisableLxpSSO) {
          updateSSOParams(location, history);
          msalSSO(msal, history);
        }
      } else {
        msal.acquireTokenSilent(request).catch(error => {
          if (error?.errorCode === 'no_account_error') {
            const requestredirect: RedirectRequest = {
              scopes: appSettings.config.defaultAuthScopes,
              authority: authorityurl,
              redirectUri: window.location.origin
            };
            msal.acquireTokenRedirect(requestredirect).catch(error => {
              ai.appInsights?.trackException({
                exception: new Error('ESIUI -useAuthenticator-acquireTokenRedirect'),
                severityLevel: SeverityLevel.Error,
                properties: { error }
              });
            });
          } else {
            if (error) {
              if (ai.appInsights) {
                ai.appInsights.trackException({
                  exception: new Error('ESIUI -useAuthenticator-acquireTokenSilent'),
                  severityLevel: SeverityLevel.Error,
                  properties: { error }
                });
              }
            }
            switch (error?.errorCode) {
              case 'login_progress_error':
                friendlyError.description = intl.formatMessage({
                  defaultMessage:
                    'There was a problem logging you in. Another login is already in progress. Please close all tabs or windows and try again.'
                });
                break;
              default:
                friendlyError.description = `There was an unexpected error while logging in.  ${error?.errorCode}`;
                break;
            }
            seterror(friendlyError);
          }
        });
      }
    }
    if (error.description !== '') {
      history.push('/about/', {
        error: error
      });
    }
  }, [
    authorityurl,
    context.inProgress,
    disableLxpSSO,
    error,
    friendlyError,
    history,
    intl,
    isAuthenticated,
    location,
    msal,
    pendingDisableLxpSSO,
    request
  ]);
};
