import { useEffect } from 'react';

/**
 * React hook to set the document title.
 *
 * @param title - The document title
 */
export const useTitle = (title: string | null | undefined) => {
  useEffect(() => {
    if (title == null) {
      return;
    }
    const oldTitle = document.title;
    document.title = title;
    return () => {
      document.title = oldTitle;
    };
  }, [title]);
};
