import { Link } from '@fluentui/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import './SkipNavLink.scss';
export const SkipNavLink: React.FunctionComponent = () => {
  const intl = useIntl();
  return (
    <Link href="#main" data-skip-link={true} aria-label={intl.formatMessage({ defaultMessage: 'Skip to main content' })}>
      <FormattedMessage defaultMessage="Skip to main content"></FormattedMessage>
    </Link>
  );
};
