import {
  ICreateProfileRequest,
  IUpdateProfileRequest,
  IUserProfile,
  IEmailVerificationRequest,
  IEmailValidationRequest,
  IUserEmailProfile,
  IVerificationCodeRequest,
  IWorkEmailVerificationCodeRequest,
  IUpdateEmailVerificationRequest,
  ITokensAvailable,
  ILearnerIdRequest,
  IUserSSOEligibility,
  IVerifyCleanupUserRequest,
  IUnlinkEmailAddressErrorRequest
} from '../../../models';
import { EndpointActions } from '../../endpoint';

export const CheckUserIsSupportUserOrNotActions = new EndpointActions<string, boolean>('CheckUserPartOf');
export const GetProfileActions = new EndpointActions<string, IUserProfile>('GetProfile');
export const CreateProfileActions = new EndpointActions<ICreateProfileRequest, IUserProfile>('CreateProfile');
export const UpdateProfileActions = new EndpointActions<IUpdateProfileRequest, IUserProfile>('UpdateProfile');
export const EmailValidationActions = new EndpointActions<IEmailValidationRequest, IUserEmailProfile>('EmailValidation');
export const EmailVerificationActions = new EndpointActions<IEmailVerificationRequest, any>('EmailVerification');
export const VerificationCodeActions = new EndpointActions<IVerificationCodeRequest, any>('VerificatonCode');
export const EmailServiceStatusActions = new EndpointActions<string, boolean>('GetEmailServiceStatus');
export const DeleteAccountActions = new EndpointActions<any, any>('DeleteAccount');
export const UpdateWorkEmailAddressActions = new EndpointActions<IWorkEmailVerificationCodeRequest, any>('UpdateWorkEmailAddress');
export const VerifyWorkEmailAddressActions = new EndpointActions<IUpdateEmailVerificationRequest, any>('VerifyWorkEmailAddress');
export const GETTokensAvailableActions = new EndpointActions<ILearnerIdRequest, ITokensAvailable>('GETTokensAvailableActions');
export const GetSSOStatusActions = new EndpointActions<string, IUserSSOEligibility>('GetSSOStatus');
export const VerifyCleanupUserActions = new EndpointActions<IVerifyCleanupUserRequest, any>('VerifyCleanupUser');
export const UnlinkEmailAddressErrorActions = new EndpointActions<IUnlinkEmailAddressErrorRequest, any>('UnlinkEmailAddressError');
export const GetSSOLinkedAccountsActions = new EndpointActions<any, IUserProfile[]>('GetSSOLinkedAccounts');
