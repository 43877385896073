import { useLocation, useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { useAuthenticatedUser } from '../../hooks/useAuthenticatedUser';
import { IApplicationState } from '../../store';
import { QueryStringParam } from '../../utilities';
import { ICustomErrorMessage, RouteErrorState, RouteEmailState, IUserSSOLinkedAccount } from '../../models/';
import { useEndpointActions } from '../../store/endpoint';
import { GetProfileActions, GetSSOLinkedAccountsActions } from '../../store/stores/user/actions';
import ApplicationLoadingSpinner from '../ApplicationLoadingSpinner/ApplicationLoadingSpinner';
import { EmailVerificationActions } from '../../store/stores/user';
import { ai } from '../ApplicationInsightsProvider/ApplicationInsightsService';
import FeatureFlagValues from '../../utilities/featureFlagValues';
import { GetFeatureFlagAction } from '../../store/stores/featureFlag';
import { NOT_LINKED_ACCOUNT_ERROR } from '../../pages/Preference/constants';
import { locales } from '../../locales';
import { useLocale } from '../LocalizationProvider/LocalizationProvider';
import { trackSSOSignIn } from '../ApplicationInsightsProvider/SSOTelemetryService';

export enum SSOStatus {
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
  Skipped = 'skipped'
}

export enum SSOFailureReason {
  SafelistedWithNoLinkedAccounts = 'safelisted-with-no-linked-account',
  EligibleWithNoLinkedAccounts = 'eligible-with-no-linked-account',
  NoLinkedAccounts = 'no-linked-accounts',
  OneLinkedAccount = 'one-linked-account',
  MultipleLinkedAccounts = 'multiple-linked-accounts'
}

type SSOState = {
  status: SSOStatus;
  failureReason?: SSOFailureReason;
  linkedAccount?: string;
  email?: string;
};

const useReferrerLocalization = () => {
  const { setLocale, locale } = useLocale();
  useEffect(() => {
    const referrer = sessionStorage.getItem(QueryStringParam.SSOReferrer);
    if (!referrer) {
      return;
    }
    const referrerPath = new URL(referrer).pathname;
    const extractedLocale = referrerPath.split('/')[1] as string | undefined; // expected format: /<locale>/<rest of path>
    const supportedLocale = locales.find(localeObject => localeObject.locale.toLowerCase() === extractedLocale && localeObject.visible);
    const newLocale = supportedLocale?.locale;
    if (newLocale && newLocale !== locale) {
      localStorage.setItem('initLocale', newLocale);
      setLocale(newLocale);
      sessionStorage.setItem('locale', newLocale);
      sessionStorage.removeItem(QueryStringParam.SSOReferrer);
    }
  }, [locale, setLocale]);
};

const useRedirect = (ssoState: SSOState) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (ssoState.status !== SSOStatus.Pending) {
      //on finished SSO, the user's session should be reset
      localStorage.setItem(QueryStringParam.IsSessionExpired, 'no');
    }

    if (ssoState.status === SSOStatus.Failed) {
      switch (ssoState.failureReason) {
        case SSOFailureReason.SafelistedWithNoLinkedAccounts:
          //clear any existing errors / login info before traveling to the page
          dispatch(EmailVerificationActions.Clear(false));
          dispatch(GetProfileActions.Clear());
          sessionStorage.clear();
          history.push('/emailvalidation/', {
            emailAddress: ssoState.email
          } as RouteEmailState);
          break;
        case SSOFailureReason.EligibleWithNoLinkedAccounts:
          dispatch(EmailVerificationActions.Clear(false));
          sessionStorage.setItem(QueryStringParam.WorkEmailAddress, ssoState.email ?? '');
          history.push('/AadLogin/');
          break;
        default:
          const friendlyError: ICustomErrorMessage = {
            richDescription: { ssoFailureReason: ssoState.failureReason, linkedAccount: ssoState.linkedAccount },
            method: 'UseEffect-SSOCompleteTemplate',
            page: 'SSOProvider'
          };
          history.push('/about/', {
            error: friendlyError,
            showLoginButton: true
          } as RouteErrorState);
      }
    } else if (ssoState.status === SSOStatus.Success) {
      const search = new URLSearchParams(location.search);
      search.delete('sso');
      search.delete('context');
      search.delete('source');
      sessionStorage.setItem(
        QueryStringParam.SSORedirect,
        JSON.stringify({
          pathname: location.pathname,
          search: `?${search.toString()}`
        })
      );
      history.push('/AadLogin/');
    }
  }, [ssoState, history, location.pathname, location.search, dispatch]);
};

export const SSOProvider: React.FC = ({ children }) => {
  const [getFeatureFlag] = useEndpointActions([GetFeatureFlagAction]);
  const [flagFetching, flagsFetched] = useSelector(
    (state: IApplicationState) => [state.FeatureFlagStore.isFetching, state.FeatureFlagStore.isFetched],
    shallowEqual
  );
  const { disableLxpSSO } = FeatureFlagValues();

  useEffect(() => {
    if (!flagFetching && !flagsFetched) {
      getFeatureFlag();
    }
  }, [getFeatureFlag, flagFetching, flagsFetched]);

  switch (disableLxpSSO) {
    case null:
      return <ApplicationLoadingSpinner />;
    case true:
      return <>{children}</>;
    default:
      return <SSOProviderImpl>{children}</SSOProviderImpl>;
  }
};

const SSOProviderImpl: React.FC = ({ children }) => {
  const location = useLocation();
  const user = useAuthenticatedUser();
  const [ssoState, setSSOState] = React.useState<SSOState>({ status: SSOStatus.Pending });
  const [profileLoaded, profileLoading, profileExists, linkedAccounts, customError, isIncomingSSOError, responseStatus] = useSelector(
    (state: IApplicationState) => [
      state.UserStore.isFetched,
      state.UserStore.isFetching,
      state.UserStore.isProfileExists,
      state.UserStore.ssoprofile.linkedAccounts,
      state.UserStore.customErrorMessage,
      state.UserStore.isIncomingSSOError,
      state.UserStore.response?.status
    ],
    shallowEqual
  ) as [boolean, boolean, boolean | null, IUserSSOLinkedAccount[] | null, ICustomErrorMessage | null, boolean, number | null];
  const [getProfile, getLinkedAccounts] = useEndpointActions([GetProfileActions, GetSSOLinkedAccountsActions]);
  const params = new URLSearchParams(location.search);
  const hasSSOQueryParams = (params.has('context') && params.get('context') === 'sso') || params.has('sso');
  const userHasNoProfile = responseStatus === 404;

  useReferrerLocalization();
  useRedirect(ssoState);

  useEffect(() => {
    if (ssoState.status !== SSOStatus.Pending && ssoState.status !== SSOStatus.Skipped) {
      const source = new URLSearchParams(location.search).get('source') ?? 'invalid';
      trackSSOSignIn(source, ssoState.status, ssoState.failureReason);
    }
  }, [hasSSOQueryParams, location.search, ssoState]);

  useEffect(() => {
    const linkedAccountsLoaded = isIncomingSSOError || linkedAccounts != null;
    if (user != null && !profileLoaded && !profileLoading && hasSSOQueryParams && linkedAccountsLoaded) {
      const workEmail = linkedAccounts?.find(account => account.email === user.username)?.workEmail ?? user.username;
      getProfile(workEmail);
    }
  }, [getProfile, hasSSOQueryParams, isIncomingSSOError, linkedAccounts, profileLoaded, profileLoading, user]);

  useEffect(() => {
    if (user != null && linkedAccounts === null && !isIncomingSSOError && hasSSOQueryParams) {
      getLinkedAccounts({ oid: user?.oid, tid: user?.tid });
    }
  }, [getLinkedAccounts, linkedAccounts, user, isIncomingSSOError, hasSSOQueryParams]);

  useEffect(() => {
    if (ai.appInsights && isIncomingSSOError) {
      const friendlyError: ICustomErrorMessage = {
        description: 'Error while processing incoming SSO request',
        method: 'UseEffect-SSOProvider',
        page: 'SSOProvider'
      };
      ai.appInsights.trackException({
        exception: new Error('ESIUI'),
        severityLevel: SeverityLevel.Error,
        properties: { isIncomingSSOError, friendlyError, customError }
      });
    }
  }, [customError, isIncomingSSOError]);

  useEffect(() => {
    if (ssoState.status !== SSOStatus.Pending) {
      return;
    }
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('context') && searchParams.get('context') === 'sso') {
      if (profileExists) {
        setSSOState({ status: SSOStatus.Success });
      } else if (profileExists === false && linkedAccounts !== null) {
        switch (linkedAccounts.length) {
          case 0:
            if (userHasNoProfile) {
              setSSOState({
                status: SSOStatus.Failed,
                failureReason: SSOFailureReason.EligibleWithNoLinkedAccounts,
                email: user?.username
              });
              // this custom behavior is exclusively for AAD profiles backed by an MSA account
            } else if (customError?.errorCode === NOT_LINKED_ACCOUNT_ERROR) {
              setSSOState({
                status: SSOStatus.Failed,
                failureReason: SSOFailureReason.SafelistedWithNoLinkedAccounts,
                email: user?.username
              });
            } else {
              setSSOState({ status: SSOStatus.Failed, failureReason: SSOFailureReason.NoLinkedAccounts });
            }
            break;
          case 1:
            setSSOState({
              status: SSOStatus.Failed,
              failureReason: SSOFailureReason.OneLinkedAccount,
              linkedAccount: linkedAccounts[0].workEmail
            });
            break;
          default:
            setSSOState({ status: SSOStatus.Failed, failureReason: SSOFailureReason.MultipleLinkedAccounts });
        }
      } else if (isIncomingSSOError) {
        setSSOState({ status: SSOStatus.Skipped });
      }
    } else if (!searchParams.has('sso')) {
      setSSOState({ status: SSOStatus.Skipped });
    }
  }, [
    profileExists,
    location.search,
    linkedAccounts,
    ssoState.status,
    setSSOState,
    customError,
    user,
    isIncomingSSOError,
    userHasNoProfile
  ]);

  if (ssoState.status !== SSOStatus.Skipped) {
    return <ApplicationLoadingSpinner />;
  }

  return <>{children}</>;
};
