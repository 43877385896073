// Your use of the content in the files referenced here is subject to the terms of the license at https://aka.ms/fabric-assets-license

// tslint:disable:max-line-length

import { IIconOptions, IIconSubset, registerIcons } from '@uifabric/styling';

/**
 * Initializes a custom font subset, give an Url to the font
 *
 * @param baseUrl The Url of the font file
 * @param options The icon options
 */

/**
 * @param baseUrl
 * @param options
 */
export function initializeIcons(baseUrl: string, options?: IIconOptions): void {
  const subset: IIconSubset = {
    style: {
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontStyle: 'normal',
      fontWeight: 'normal',
      speak: 'none'
    },
    fontFace: {
      fontFamily: `"FabricMDL2Icons"`,
      src: `url('${baseUrl}') format('woff')`
    },
    icons: {
      Add: '\uE710',
      AlertSolid: '\uF331',
      AcceptMedium: '\uF78C',
      Back: '\uE72B',
      Calendar: '\uE787',
      Cancel: '\uE711',
      Certificate: '\uEB95',
      CheckMark: '\uE73E',
      ChevronDown: '\uE70D',
      ChevronLeft: '\uE76B',
      ChevronRight: '\uE76C',
      ChevronUp: '\uE70E',
      Completed: '\uE930',
      CompletedSolid: '\uEC61',
      Contact: '\uE77B',
      ContactCard: '\uEEBD',
      D365TalentLearn: '\uF6BB',
      Devices3: '\uEA6C',
      DoubleChevronDown: '\uEE04',
      DoubleChevronLeft: '\uEDBE',
      DoubleChevronRight: '\uEDBF',
      DoubleChevronUp: '\uEDBD',
      eDiscovery: '\uE370',
      Error: '\uE783',
      ErrorBadge: '\uEA39',
      Event: '\uECA3',
      EventInfo: '\uED8B',
      Forward: '\uE72A',
      Group: '\uE902',
      Info: '\uE946',
      LearningTools: '\uF7DB',
      Link: '\uE71B',
      Message: '\uE8BD',
      MiniExpand: '\uE93A',
      More: '\uE712',
      MoreVertical: '\uF2BC',
      NavigateExternalInline: '\uF35F',
      PageRight: '\uE761',
      PDF: '\uEA90',
      Pin: '\uE718',
      PublishCourse: '\uF699',
      TextDocumentEdit: '\uE43B',
      Main_ProductivityWorkshop: '\uF429',
      Main_GameLearning: '\uE7FC',
      Search: '\uE721',
      Wheelchair: '\uF31F',
      World: '\uE909',
      Warning: '\uE7BA',
      SearchCampaigns: '\uF5E2'
    }
  };

  registerIcons(subset, options);
}
