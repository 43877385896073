import { QueryStringParam } from '../utilities';

export const useAuthority = () => {
  let authority = sessionStorage.getItem(QueryStringParam.Authority) || 'https://login.microsoftonline.com/common/';
  const isAad = sessionStorage.getItem(QueryStringParam.IsAad);
  if (isAad === 'true') {
    authority = sessionStorage.getItem(QueryStringParam.Authority) || process.env.REACT_APP_AUTHENTICATION_AUTHORITY || '';
  } else if (isAad === 'false') {
    authority = sessionStorage.getItem(QueryStringParam.Authority) || process.env.REACT_APP_AUTHENTICATION_MSA_AUTHORITY || '';
  } else if (document.referrer.indexOf('login.microsoftonline') > -1) {
    authority = document.referrer + 'common/';
    sessionStorage.setItem(QueryStringParam.Authority, authority);
  }
  return authority;
};
