import { withAITracking } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ai } from './ApplicationInsightsService';

interface IAppInsightState {
  /** Indicates whether or not the Application Insights component has been initialized */
  initialized: boolean;
}

interface IAppInsightProviderProps {
  /** The instrumentation key for the remote App Insights instance in Azure cloud */
  instrumentationKey: string;
}

type IAllProps = IAppInsightProviderProps & RouteComponentProps;

/**
 * This Component provides telemetry with Azure App Insights
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class ApplicationInsightsProvider extends React.Component<IAllProps, IAppInsightState> {
  /** The components internal state */
  public state: IAppInsightState = {
    initialized: false
  };

  /** Executes when the component is being mounted in the DOM */
  public componentDidMount = () => {
    const { history, instrumentationKey } = this.props;
    const { initialized } = this.state;

    if (!instrumentationKey) {
      throw new Error('Could not initialize the Application Insights Provider. No instrumentation key has been specified.');
    }
    if (!initialized) {
      ai.initialize(instrumentationKey, history);
      this.setState({ initialized: true });
    }
  };

  /** Renders the component */
  public render = () => {
    const { children } = this.props;

    return <React.Fragment>{children}</React.Fragment>;
  };
}

export default withRouter(withAITracking(ai.reactPlugin, ApplicationInsightsProvider));
