import React from 'react';

import { LoaderSection } from '../Loading/LoaderSection';

export const ApplicationLoadingSpinner: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <LoaderSection />
    </React.Fragment>
  );
};

export default ApplicationLoadingSpinner;
