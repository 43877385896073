import { takeLeading } from 'redux-saga/effects';

import { IDeliveryInput, IDeliveryDesc } from '../../../models';
import Api from '../../api/Api';
import { EndpointSaga, IEndpointSagaDictionary } from '../../endpoint';
import { DeliveryDescActions } from './actions';

export const sagas: IEndpointSagaDictionary = {
  DeliveryDescSaga: new EndpointSaga<IDeliveryInput, IDeliveryDesc>(DeliveryDescActions, Api.DELIVERYDESC, takeLeading)
};
