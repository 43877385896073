import { takeLeading } from 'redux-saga/effects';

import Api from '../../api/Api';
import { EndpointSaga, IEndpointSagaDictionary } from '../../endpoint';
import { IRequestAdditionalTokensRequest, IRequestAdditionalTokensResponse } from '../../../models/RequestAdditionalTokens';
import { SendRequestForAdditonalTokensActions } from './actions';

export const sagas: IEndpointSagaDictionary = {
  SendRequestForAdditonalTokensActions: new EndpointSaga<IRequestAdditionalTokensRequest[], IRequestAdditionalTokensResponse[]>(
    SendRequestForAdditonalTokensActions,
    Api.SEND_REQUEST_AdditionalTokens,
    takeLeading
  )
};
