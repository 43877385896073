import { AnyAction, Reducer } from 'redux';

import { ICopilotTokenResponse } from '../../../models';
import { EndpointStateFactory, EndpointStore, IEndpointState } from '../../endpoint';
import { GetCopilotActions } from './actions';
import { sagas } from './sagas';

export * from './actions';

/** The name of the state, this will be the Redux property that data is stored under */
const storeName = 'CopilotStore';

export interface ICopilotState extends IEndpointState {
  /** The certification state */
  readonly copilot: ICopilotTokenResponse;
}

/** IState acts only as a helper type to make store logic easier to copy and paste */
type IState = ICopilotState;

/**
 * The initial state of the endpoint store. Endpoint stores have base states (ex: isFetching, isFetched, etc.).
 *  We use the EndointStateFactory to make sure to populate these values with the correct defaults
 */
export const initialCopilotState: IState = new EndpointStateFactory<IState>().getInitialState({
  copilot: { token: '' }
});

/**
 * Reducer responsible for managing the slice of state that belongs to the store
 *
 * @param state - Initial certification state
 * @param action - The action
 */
const reducer: Reducer<IState> = (state: IState = initialCopilotState, action: AnyAction): IState => {
  switch (action.type) {
    case GetCopilotActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        copilot: action.payload
      };
    default:
      return state;
  }
};

/** Responsible for handling the related state in the Redux store */
export const CopilotStore = new EndpointStore<IState>(storeName, initialCopilotState, reducer, sagas, [GetCopilotActions]);
