///<reference types="@wcp/wcp-consent" />

import Cookies from 'universal-cookie';

import { Helper } from './utilities/Helper';

const locale = Helper.getLocale();
let siteConsent!: WcpConsent.SiteConsent;
let currentAnalytics: boolean = sessionStorage.getItem('isAnalytics') === 'true';

// Init method
WcpConsent &&
  WcpConsent.init(
    locale,
    'cookie-banner',
    (err, _siteConsent) => {
      if (err) {
        alert(err);
      } else {
        siteConsent = _siteConsent!;
        if (typeof window !== 'undefined') {
          const wnd = window as any;
          wnd['siteConsent'] = siteConsent;
        }

        if (siteConsent?.isConsentRequired) {
          checkForAnalytics();
        } else {
          allowForClarity();
        }
      }
    },
    onConsentChanged
  );

// call back method when consent is changed by user
/**
 * @param newConsent
 */
function onConsentChanged(newConsent: Record<WcpConsent.consentCategories, boolean>) {
  let reload = false;
  if (currentAnalytics !== newConsent.Analytics) {
    sessionStorage.setItem('isAnalytics', newConsent.Analytics.toString());
    currentAnalytics = newConsent.Analytics;
    reload = !newConsent.Analytics;
  }

  checkForAnalytics(reload);
}

/**
 *
 */
function allowForClarity() {
  // allow for Clarity
  if (typeof window !== 'undefined') {
    const wnd = window as any;
    wnd['clarity']('consent');
  }
}

/**
 * @param reload
 */
function removeAnalyticCookies(reload: boolean) {
  setTimeout(() => {
    const analyticCookies: string[] = ['_clck', '_clsk', 'MUID', 'CLID', 'ANONCHK', 'SM'];
    const cookies = new Cookies();

    analyticCookies.forEach(cookie => {
      cookies.remove(cookie);
    });

    if (reload) {
      window.location.reload();
    }
  }, 3000);
}

/**
 * @param reload
 */
function checkForAnalytics(reload = false) {
  const isAnalytics: boolean = siteConsent.getConsentFor(WcpConsent.consentCategories.Analytics);
  if (isAnalytics) {
    allowForClarity();
  } else {
    removeAnalyticCookies(reload);
  }
}
