interface IMSclassesItemTypeDictionary {
  /** The title of the learning modality */
  modalityLabel: string;

  /** The name of the icon to use */
  iconName: string;

  /** The label text for the action button */
  actionLabel: string;

  /** The color that represents the modality type */
  color: string;

  /** The color for the text of the modality type */
  textColor: string;
}

/**
 * The ContentType enum represents the types of configurable content to be displayed in a
 * learning path item template
 */
export enum OneDayClassesContentType {
  ModalityLabel = 'modalityLabel',
  IconName = 'iconName',
  ActionLabel = 'actionLabel',
  Color = 'color',
  TextColor = 'textColor'
}

/** A class to resolve configurable content for a Learning Path Item */
export abstract class OneDayClassesContent {
  /**
   * The learning path types.
   * Note: These should be lowercased since the methods below convert
   * the event type to a lower-cased string.
   */
  private static readonly _MsclassesItemContent: { [key: string]: IMSclassesItemTypeDictionary } = {
    ilt: {
      modalityLabel: 'Instructor-Led',
      iconName: 'Group',
      actionLabel: 'Register',
      color: '#C73ECC',
      textColor: '#BD3AC1'
    },
    'self-paced': {
      modalityLabel: 'Online, Self-Paced',
      iconName: 'PublishCourse',
      actionLabel: 'Register',
      color: '#008575',
      textColor: '#008575'
    },
    vilt: {
      modalityLabel: 'Online, Self-Paced',
      iconName: 'NavigateExternalInline',
      actionLabel: 'Register',
      color: '#008575',
      textColor: '#008575'
    },
    workshop: {
      modalityLabel: 'Facilitated Workshop',
      iconName: 'Event',
      actionLabel: 'Register',
      color: '#FFB900',
      textColor: '#FFB900'
    },
    hackathon: {
      modalityLabel: 'Hackathon',
      iconName: 'Event',
      actionLabel: 'Register',
      color: '#FFB900',
      textColor: '#FFB900'
    },
    'virtual training days': {
      modalityLabel: 'virtual training days',
      iconName: 'NavigateExternalInline',
      actionLabel: 'Register',
      color: '#702573',
      textColor: '#702573'
    },
    'advanced role based': {
      modalityLabel: 'advanced role based',
      iconName: 'NavigateExternalInline',
      actionLabel: 'Register',
      color: '#C73ECC',
      textColor: '#BD3AC1'
    },
    'game learning': {
      modalityLabel: 'game learning',
      iconName: 'NavigateExternalInline',
      actionLabel: 'Register',
      color: '#9B0F62',
      textColor: '#9B0F62'
    },
    main_multidaymsclass: {
      modalityLabel: 'microsoft-delivered courses',
      iconName: 'NavigateExternalInline',
      actionLabel: 'Register',
      color: '#C73ECC',
      textColor: '#BD3AC1'
    },
    'exam prep': {
      modalityLabel: 'exam prep',
      iconName: 'NavigateExternalInline',
      actionLabel: 'Register',
      color: '#ffb934',
      textColor: '#6A4B16'
    },
    main_deliveryonlymsclass: {
      modalityLabel: 'courses shared with you',
      iconName: 'NavigateExternalInline',
      actionLabel: 'Register',
      color: '#0065B3',
      textColor: '#0065B3'
    },
    'courses shared with you': {
      modalityLabel: 'courses shared with you',
      iconName: 'NavigateExternalInline',
      actionLabel: 'Register',
      color: '#0065B3',
      textColor: '#0065B3'
    },
    'delivery only': {
      modalityLabel: 'courses shared with you',
      iconName: 'NavigateExternalInline',
      actionLabel: 'Register',
      color: '#0065B3',
      textColor: '#0065B3'
    },
    main_onedaymsclass: {
      modalityLabel: 'microsoft training days',
      iconName: 'NavigateExternalInline',
      actionLabel: 'Register',
      color: '#008575',
      textColor: '#008575'
    },
    'microsoft learn cloud games': {
      modalityLabel: 'microsoft learn cloud games',
      iconName: 'NavigateExternalInline',
      actionLabel: 'Register',
      color: '#9B0F62',
      textColor: '#9B0F62'
    },
    default: {
      modalityLabel: 'Learning Content',
      iconName: 'NavigateExternalInline',
      actionLabel: 'Register',
      color: '#C73ECC',
      textColor: '#BD3AC1'
    }
  };

  /**
   * Resolves the learning path content for a particular item by mapping an event type
   * and the type of content to the content itself
   *
   * @param {string} eventType The learning path item event type
   * @param {OneDayClassesContentType} contentType The type of content to be resolved
   * @returns {string} The content mapped to the modality and content type
   */
  public static RESOLVE(eventType: string, contentType: OneDayClassesContentType): string {
    if (!eventType) {
      return this._MsclassesItemContent.default[contentType];
    }

    eventType = eventType.toLowerCase();
    const itemMeta = this._MsclassesItemContent[eventType];

    if (!itemMeta) {
      return this._MsclassesItemContent.default[contentType];
    }

    return itemMeta[contentType];
  }

  /**
   * Given a list of learning path content items, produce a CSS linear gradient with the proper modality theme colors
   *
   * @param {string} modality The array of items the gradient should represent
   * @returns {string} The string gradient value that can be set to a CSS property such as "border-image"
   */
  public static GET_BORDER_IMAGE_VALUE(modality: string): string {
    if (modality.length === 0) {
      return 'none';
    }
    if (modality === 'findmore') {
      return OneDayClassesContent.GET_BORDER_IMAGE_VALUE_FINDMORE(modality);
    }
    if (modality === 'microsoft learn cloud games') {
      return OneDayClassesContent.GET_BORDER_IMAGE_VALUE_GAMES(modality);
    }
    if (modality === 'getcertification') {
      return OneDayClassesContent.GET_BORDER_IMAGE_VALUE_CERTIFICATE(modality);
    } else {
      const color = OneDayClassesContent.RESOLVE(modality, OneDayClassesContentType.Color);
      let borderTopColorValue = '';
      borderTopColorValue = `${color}`;
      return `${borderTopColorValue}`;
    }
  }
  /**
   * Given a list of learning path content items, produce a CSS linear gradient with the proper modality theme colors
   *
   * @param {string} modality The array of items the gradient should represent
   * @returns {string} The string gradient value that can be set to a CSS property such as "border-image"
   */
  public static GET_BORDER_IMAGE_VALUE_FINDMORE(modality: string): string {
    if (!modality.length) {
      return 'none';
    }

    let borderTopColorValue = '';
    borderTopColorValue = `${'#000000'}`;
    return `${borderTopColorValue}`;
  }

  /**
   * Given a list of learning path content items, produce a CSS linear gradient with the proper modality theme colors
   *
   * @param {string} modality The array of items the gradient should represent
   * @returns {string} The string gradient value that can be set to a CSS property such as "border-image"
   */
  public static GET_BORDER_IMAGE_VALUE_GAMES(modality: string): string {
    if (!modality.length) {
      return 'none';
    }

    let borderTopColorValue = '';
    borderTopColorValue = `${'#9B0F62'}`;
    return `${borderTopColorValue}`;
  }
  /**
   * Given a list of learning path content items, produce a CSS linear gradient with the proper modality theme colors
   *
   * @param {string} modality The array of items the gradient should represent
   * @returns {string} The string gradient value that can be set to a CSS property such as "border-image"
   */
  public static GET_BORDER_IMAGE_VALUE_CERTIFICATE(modality: string): string {
    if (modality.length === 0) {
      return 'none';
    }

    let borderTopColorValue = '';
    borderTopColorValue = `${'#0078D7'}`;
    return `${borderTopColorValue}`;
  }
}
