import { AnyAction, Reducer } from 'redux';

import { ILanguage, IModality, ITimezone, IRole, ICountry, IMeasureUpLTISettings, INotifications, IESIAgreement } from '../../../models';
import { EndpointStateFactory, EndpointStore, IEndpointState } from '../../endpoint';
import {
  GetLanguagesActions,
  GetTimeZonesActions,
  GetModalitiesActions,
  GetRolesActions,
  GetCountryActions,
  GetMeasureUpLTISettingsActions,
  GetNotificationsActions,
  GetESIAgreementActions,
  GetEmailCodeVerificationStatusActions
} from './actions';
import { sagas } from './sagas';

export * from './actions';

/** The name of the state, this will be the Redux property that data is stored under */
const storeName = 'EnrollmentPlanStore';

export interface IEnrollmentPlanState extends IEndpointState {
  /** The enrollment plan state */
  readonly languages: ILanguage[];
  readonly timezones: ITimezone[];
  readonly modalities: IModality[];
  readonly roles: IRole[];
  readonly countries: ICountry[];
  readonly notifications: INotifications[];
  readonly measureUpLTISettings: IMeasureUpLTISettings;
  esiAgreement: IESIAgreement[];
  readonly isEmailCodeVerified: boolean | false;
}

/** IState acts only as a helper type to make store logic easier to copy and paste */
type IState = IEnrollmentPlanState;

/**
 * The initial state of the endpoint store. Endpoint stores have base states (ex: isFetching, isFetched, etc.).
 *  We use the EndointStateFactory to make sure to populate these values with the correct defaults
 */
export const initialEnrollmentPlanState: IState = new EndpointStateFactory<IState>().getInitialState({
  timezones: [],
  languages: [],
  modalities: [],
  roles: [],
  countries: [],
  notifications: [],
  measureUpLTISettings: undefined,
  esiAgreement: [],
  isEmailCodeVerified: undefined
});

/**
 * Reducer responsible for managing the slice of state that belongs to the store
 *
 * @param state - Initial enrollment plan state
 * @param action - The action
 */
const reducer: Reducer<IState> = (state: IState = initialEnrollmentPlanState, action: AnyAction): IState => {
  switch (action.type) {
    case GetLanguagesActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        languages: action.payload
      };
    case GetModalitiesActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        modalities: action.payload
      };
    case GetTimeZonesActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        timezones: action.payload
      };
    case GetRolesActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        roles: action.payload
      };
    case GetCountryActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        countries: action.payload
      };
    case GetMeasureUpLTISettingsActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        measureUpLTISettings: action.payload
      };
    case GetEmailCodeVerificationStatusActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        isEmailCodeVerified: action.payload
      };
    case GetNotificationsActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        notifications: action.payload
      };
    case GetNotificationsActions.ActionTypes.ExecuteError:
      return {
        ...state,
        notifications: []
      };
    case GetESIAgreementActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        esiAgreement: action.payload
      };
    case GetESIAgreementActions.ActionTypes.ExecuteError:
      return {
        ...state,
        esiAgreement: action.payload
      };
    default:
      return state;
  }
};

/** Responsible for handling the related state in the Redux store */
export const EnrollmentPlanStore = new EndpointStore<IState>(storeName, initialEnrollmentPlanState, reducer, sagas, [
  GetLanguagesActions,
  GetModalitiesActions,
  GetCountryActions,
  GetMeasureUpLTISettingsActions,
  GetTimeZonesActions,
  GetRolesActions,
  GetEmailCodeVerificationStatusActions,
  GetESIAgreementActions,
  GetNotificationsActions
]);
