import React from 'react';
import { Image } from '@fluentui/react';
import { FormattedMessage } from 'react-intl';

import CopilotSvg from '../../assets/images/coassistLogo-sm.svg';

import './CopilotLogo.scss';

const CopilotLogo = () => {
  return (
    <>
      <Image src={CopilotSvg} alt="click to open assist" className="copilotIcon" />
      <span className="previewText">
        <FormattedMessage defaultMessage="PRE" />
      </span>
    </>
  );
};

export default CopilotLogo;
