import React from 'react';
import './BackoutburgerMenu.scss';

const BackoutBurgerMenu = () => {
  return (
    <>
      <div id="backoutDiv" className="backoutBurger">
        <div />
      </div>
    </>
  );
};
export default BackoutBurgerMenu;
