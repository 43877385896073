import { ILearningPathItem } from '../models';

interface ILearningPathItemTypeDictionary {
  /** The title of the learning modality */
  modalityLabel: string;

  /** The name of the icon to use */
  iconName: string;

  /** The label text for the action button */
  actionLabel: string;

  /** The color that represents the modality type */
  color: string;
}

/**
 * The ContentType enum represents the types of configurable content to be displayed in a
 * learning path item template
 */
export enum LearningPathContentType {
  ModalityLabel = 'modalityLabel',
  IconName = 'iconName',
  ActionLabel = 'actionLabel',
  Color = 'color'
}

/** A class to resolve configurable content for a Learning Path Item */
export abstract class LearningPathContent {
  /**
   * The learning path types.
   * Note: These should be lowercased since the methods below convert
   * the event type to a lower-cased string.
   */
  private static readonly _learningPathItemContent: { [key: string]: ILearningPathItemTypeDictionary } = {
    ilt: {
      modalityLabel: 'Instructor-Led',
      iconName: 'Group',
      actionLabel: 'Register',
      color: '#8661C5'
    },
    'self-paced': {
      modalityLabel: 'Online, Self-Paced',
      iconName: 'PublishCourse',
      actionLabel: 'Launch',
      color: '#008575'
    },
    workshop: {
      modalityLabel: 'Facilitated Workshop',
      iconName: 'Event',
      actionLabel: 'Register',
      color: '#FFB900'
    },
    hackathon: {
      modalityLabel: 'Hackathon',
      iconName: 'Event',
      actionLabel: 'Register',
      color: '#FFB900'
    },
    default: {
      modalityLabel: 'Learning Content',
      iconName: 'PublishCourse',
      actionLabel: 'Register',
      color: '#8661C5'
    }
  };

  /**
   * Resolves the learning path content for a particular item by mapping an event type
   * and the type of content to the content itself
   *
   * @param {string} eventType The learning path item event type
   * @param {LearningPathContentType} contentType The type of content to be resolved
   * @returns {string} The content mapped to the modality and content type
   */
  public static RESOLVE(eventType: string, contentType: LearningPathContentType): string {
    if (!eventType) {
      return this._learningPathItemContent.default[contentType];
    }

    eventType = eventType.toLowerCase();
    const itemMeta = this._learningPathItemContent[eventType];

    if (!itemMeta) {
      return this._learningPathItemContent.default[contentType];
    }

    return itemMeta[contentType];
  }

  /**
   * Given a list of learning path content items, produce a CSS linear gradient with the proper modality theme colors
   *
   * @param {ILearningPathItem[]} items The array of items the gradient should represent
   * @returns {string} The string gradient value that can be set to a CSS property such as "border-image"
   */
  public static GET_BORDER_IMAGE_VALUE(items: ILearningPathItem[]): string {
    if (items.length === 0) {
      return 'none';
    }

    const uniqueModalities = [...new Set(items.map((item: ILearningPathItem) => item.modality))];
    const colors: string[] = uniqueModalities.map((modality: string) =>
      LearningPathContent.RESOLVE(modality, LearningPathContentType.Color)
    );
    const itemWidth = 80 / colors.length;
    let gradientValue = '';

    for (const [idx, color] of colors.entries()) {
      if (idx === 0) {
        gradientValue = `${color} ${itemWidth}px`;
      } else {
        gradientValue += `, ${color} ${itemWidth * idx}px, ${color} ${itemWidth * (idx + 1)}px`;
      }
    }

    return `linear-gradient(to right, ${gradientValue}, transparent ${itemWidth * uniqueModalities.length}px, transparent 100%) 1`;
  }
}
