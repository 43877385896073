import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { IApplicationState } from '../../store';
import { useEndpointActions } from '../../store/endpoint';
import { GetProfileActions } from '../../store/stores/user/actions';
import ApplicationLoadingSpinner from '../ApplicationLoadingSpinner/ApplicationLoadingSpinner';
import { QueryStringParam } from '../../utilities';

/**
 * Component to verifiy the user has accepted the Eula before showing the children components
 *
 * @param props The properties
 */
const EulaProtection: React.FunctionComponent = props => {
  const [isProfileFetched] = useSelector(
    (state: IApplicationState) => [state.UserStore.isFetched, state.UserStore.isError, state.UserStore.response],
    shallowEqual
  );

  const [getProfile] = useEndpointActions([GetProfileActions]);
  const userEmail = sessionStorage.getItem(QueryStringParam.WorkEmailAddress);
  React.useEffect(() => {
    getProfile(userEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isProfileFetched) {
    // If the profile doesn't exist, redirect the user to the Registration page
    return <React.Fragment>{props.children}</React.Fragment>;
  } else {
    return <ApplicationLoadingSpinner />;
  }
};

export default EulaProtection;
