export const isSigninDisableFlag = 'FeatureManagement:WelcomeSigninDisable';
export const isWelcomePageNofification = `Welcome_${localStorage.getItem('locale')}`;
export const isMaintenanceModeFlag = 'FeatureManagement:isMaintenanceMode';
export const isSearchFeatureEnabled = 'FeatureManagement:isSearchFeatureEnabled';
export const showNewTimezoneFlag = 'FeatureManagement:showNewTimezone';
export const disableSessionTimeout = 'FeatureManagement:disableSessionTimeout';
export const sessionTimeout = 30 * 60 * 1000;
export const sessionExpireCountdownTime = 30;
export const disableMultiRoleAndFiltersInUrl = 'FeatureManagement:disableMultiRoleAndFiltersInUrl';
export const hideULJPhase2Flag = 'FeatureManagement:disableUljPhase-2';
export const extraSearchODataFilters = 'FeatureManagement:extraSearchODataFilters';
export const hideBLSessionsFlag = 'FeatureManagement:hideBLSessions';
export const disableResendInvitationLink = 'FeatureManagement:disableResendInvitationLink';
export const disableBLTeamsJoinLink = 'FeatureManagement:disableBLTeamsJoinLink';
export const disableTermsAndConditions = 'FeatureManagement:disableTermsAndConditions';
export const disableProjectReadinessFlag = 'FeatureManagement:disableProjectReadiness';
export const disableFrenchEpicFlag = 'FeatureManagement:disableFrenchEpic';
export const disableGermanEpicFlag = 'FeatureManagement:disableGermanEpic';
export const disableTPID = 'FeatureManagement:disabletpid';
export const lxpTermsConditions = 'LxP - Terms and Conditions';
export const lxpProgramGuidelines = 'LXP - ESI Program Guidelines';
export const lxpTermsConditionsTitle = 'ESI Learning Opportunities Agreement';
export const lxpProgramGuidelinesTitle = 'ESI Program Guidelines';
export const disableMSLearnSSOForLandingPageAndNavs = 'FeatureManagement:disableMSLearnSSOForLandingPageAndNavs';
export const disableMSLearnSSOForCertificationPage = 'FeatureManagement:disableMSLearnSSOForCertificationPage';
export const disableMSLearnSSOForDeliveriesPage = 'FeatureManagement:disableMSLearnSSOForDeliveriesPage';
export const enableCopilotV2 = 'FeatureManagement:enableCopilotV2';
export const disableLxpSSOFlag = 'FeatureManagement:disableLxpSSO';
export const microsoftLearnLink = 'https://aka.ms/MicrosoftLearnOverview';
export const examZoneLink = 'https://aka.ms/ExamReadinessZoneOverview';
export const virtualTrainingLink = 'https://aka.ms/RegisterMicrosoftVirtualTrainingDays';
export const esiTrainingLink = 'https://aka.ms/ESITrainingJourneysOverview';
export const profileLxpLink = 'https://aka.ms/ProfileCreationLxP';
export const esiSupportLink = 'https://aka.ms/ESISupportOverview';
export const esiSupcaseLink = 'https://aka.ms/ESISupportCaseOverview';
export const disableELAUnAuthFlowFlag = 'FeatureManagement:disableELAUnAuthFlowFlag';
