import { takeLeading, takeLatest } from 'redux-saga/effects';

import {
  ILearningPath,
  ILearningPathCard,
  ILearningPathGroups,
  IFilterInputs,
  IMSClassesCatalog,
  ILearningPartners,
  IFilterInputMyDeliveries
} from '../../../models';
import Api from '../../api/Api';
import { EndpointSaga, IEndpointSagaDictionary } from '../../endpoint';
import {
  GetLearningPathActions,
  GetLearningPathCardActions,
  GetLearningPathGroupsActions,
  MSCatalogActions,
  GetLearningPartnersActions,
  MSSingleCatalogActions,
  GetMyDeliveriesActions,
  GetSearchDeliveriesStatusActions
} from './actions';

export const sagas: IEndpointSagaDictionary = {
  GetLearningPathSaga: new EndpointSaga<string, ILearningPath>(GetLearningPathActions, Api.GET_LEARNING_PATH, takeLeading),
  GetLearningPathCardSaga: new EndpointSaga<string, ILearningPathCard[]>(
    GetLearningPathCardActions,
    Api.GET_DEFAULT_LEARNING_PATH,
    takeLeading
  ),
  GetLearningPathGroupsSaga: new EndpointSaga<string, ILearningPathGroups[]>(
    GetLearningPathGroupsActions,
    Api.GET_LEARNING_PATH_GROUPS,
    takeLeading
  ),
  MSCatalogActionsSaga: new EndpointSaga<IFilterInputs, IMSClassesCatalog>(MSCatalogActions, Api.MSCATALOG, takeLatest),
  MSSingleCatalogActionsSaga: new EndpointSaga<IFilterInputs, IMSClassesCatalog>(MSSingleCatalogActions, Api.MSCATALOG, takeLatest),
  GetLearningParnerSaga: new EndpointSaga<string, ILearningPartners[]>(
    GetLearningPartnersActions,
    Api.GET_DEFAULT_LEARNING_PARTNER,
    takeLeading
  ),
  GetMyDeliveriesSaga: new EndpointSaga<IFilterInputMyDeliveries, IMSClassesCatalog>(
    GetMyDeliveriesActions,
    Api.GET_MyDeliveries,
    takeLatest
  ),
  GetSearchDeliveriesStatusSaga: new EndpointSaga<IFilterInputMyDeliveries, IMSClassesCatalog>(
    GetSearchDeliveriesStatusActions,
    Api.GET_SearchDeliveriesStatus,
    takeLatest
  )
};
