import { IServiceConfiguration } from './';

/** Interface to enforce the contract for what is required to construct an endpoint path */
export interface IEndpointConfiguration {
  /** The configurations for the service */
  service: IServiceConfiguration;

  /** The path of the endpoint relative to the service */
  endpoint: string;
}

/** Responsible for owning endpoint path configurations and constructing the path Url */
export class EndpointConfiguration {
  /** The configuration values of the endpoint */
  public readonly config: IEndpointConfiguration;

  constructor(config: IEndpointConfiguration) {
    this.config = config;
  }

  /** Constructs a full url to the endpoint using the confiugration values provided when the class was created */
  public getEndpointUrl = () => {
    const { endpoint, service } = this.config;
    return `${service.base + endpoint}`;
  };
}
