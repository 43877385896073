export enum QueryStringParam {
  EnrollmentPlanID = 'lpid',
  AccountLinking = 'accountLinking',
  LinkingRequestId = 'requestId',
  LinkingErrorFlag = 'isError',
  DomainHint = 'hint',
  WorkEmailAddress = 'workEmailAddress',
  loginEmail = 'loginemail',
  DeliveryId = 'delid',
  q = 'q',
  IsAad = 'isAad',
  IsAuthenticated = 'isAuthenticated',
  Authority = 'authority',
  NewWorkEmailUpdate = 'newWorkEmailUpdate',
  PreferredEmailUpdate = 'PreferredEmailUpdate',
  SelectedRoles = 'roles',
  SelectedAvailability = 'avail',
  SelectedTimezones = 'tz',
  SelectedLanguages = 'lang',
  SelectedModalities = 'exp',
  SelectedLearningOPtion = 'learningoption',
  IsESITermsAccepted = 'isESITermsAccepted',
  SSORedirect = 'SSORedirect',
  IsSessionExpired = 'isSessionExpired',
  SSOReferrer = 'SSOReferrer'
}
/**
 * A helper class to perform operations against the query string parameters in the URL
 *
 * @param url
 * @param key
 * @param paramKey
 * @param paramValue
 * @param existingSearchParams
 * @param value
 */
export abstract class QueryStringHelper {
  /**
   * Gets a query string parameter given the parameter name
   *
   * @param {string} param The name of the parameter to get
   * @returns {string | null} A string representing the parameter value or null if the parameter doesn't exist
   */
  public static get = (param: string): string | null => {
    const { search } = window.location;
    const urlParams = new URLSearchParams(search);

    return urlParams.get(param);
  };

  /**
   * Generates a new querystring based on existing search params and newly changed params
   *
   * @param {URLSearchParams} existingSearchParams URLSearchParams Existing UrlSearchParams of the url
   * @param {string} paramKey string  Querystring parameter key that changed
   * @param {string} paramValue string  Querystring parameter value that changed
   * @returns {string | null} A string representing the new queystring part of the url or null if the parameters dont exist
   */
  public static generateNewQueryString = (existingSearchParams: URLSearchParams, paramKey: string, paramValue: string[]): string => {
    const newQueryStringmap = new Map<string, string>();
    if (existingSearchParams.has(paramKey)) existingSearchParams.delete(paramKey);
    let searchQuery = '';
    const searchQueryKey = 'q';
    const allQueryParamsMap = new Map<string, string>();
    if (existingSearchParams.has('q')) {
      searchQuery = existingSearchParams.get('q') ?? '';
    }

    for (const [key, val] of existingSearchParams.entries()) {
      if (key !== searchQueryKey && key !== paramKey) allQueryParamsMap.set(key, val);
    }
    paramValue = paramValue.sort();
    if (paramValue.length > 0) {
      switch (paramKey) {
        case QueryStringParam.SelectedAvailability:
          newQueryStringmap.set(QueryStringParam.SelectedAvailability, paramValue.toString());
          break;
        case QueryStringParam.SelectedLanguages:
          newQueryStringmap.set(QueryStringParam.SelectedLanguages, paramValue.toString());
          break;
        case QueryStringParam.SelectedModalities:
          newQueryStringmap.set(QueryStringParam.SelectedModalities, paramValue.toString());
          break;
        case QueryStringParam.SelectedRoles:
          newQueryStringmap.set(QueryStringParam.SelectedRoles, paramValue.toString());
          break;
        case QueryStringParam.SelectedLearningOPtion:
          newQueryStringmap.set(QueryStringParam.SelectedLearningOPtion, paramValue.toString());
          break;
        case QueryStringParam.SelectedTimezones:
          newQueryStringmap.set(QueryStringParam.SelectedTimezones, paramValue.toString());
          break;
        default:
          break;
      }
    }
    if (newQueryStringmap.size === 1) allQueryParamsMap.set(paramKey, newQueryStringmap.get(paramKey) ?? '');
    const sortedAscQueryParamsMap = new Map([...allQueryParamsMap].sort());
    const newURLSearchParams = new URLSearchParams([[searchQueryKey, searchQuery]]);
    for (const [key, value] of sortedAscQueryParamsMap) {
      newURLSearchParams.append(key, value);
    }
    return newURLSearchParams.toString();
  };
}
