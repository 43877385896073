import { AnyAction, Reducer } from 'redux';

import { ICustomErrorMessage } from '../../../models';
import { IFeatureFlag } from '../../../models/FeatureFlag';
import { ErrorMessageMapper } from '../../../utilities';
import { EndpointStateFactory, EndpointStore, IEndpointState } from '../../endpoint';
import { GetFeatureFlagAction } from './actions';
import { sagas } from './sagas';

export * from './actions';

/** The name of the state, this will be the Redux property that data is stored under */
const storeName = 'FeatureFlagStore';

export interface IFeatureFlagState extends IEndpointState {
  /** The Feature Flag state */
  readonly featureFlags: IFeatureFlag[];
  readonly customErrorMessage: ICustomErrorMessage | null;
}

/** IState acts only as a helper type to make store logic easier to copy and paste */
type IState = IFeatureFlagState;

/**
 * The initial state of the endpoint store. Endpoint stores have base states (ex: isFetching, isFetched, etc.).
 *  We use the EndointStateFactory to make sure to populate these values with the correct defaults
 */
export const initialFeatureFlagState: IState = new EndpointStateFactory<IState>().getInitialState({
  featureFlags: [],
  customErrorMessage: null
});

/**
 * Reducer responsible for managing the slice of state that belongs to the store
 *
 * @param state
 * @param action
 */
const reducer: Reducer<IState> = (state: IState = initialFeatureFlagState, action: AnyAction): IState => {
  switch (action.type) {
    case GetFeatureFlagAction.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        featureFlags: action.payload
      };
    case GetFeatureFlagAction.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    default:
      return state;
  }
};

/** Responsible for handling the related state in the Redux store */
export const FeatureFlagStore = new EndpointStore<IState>(storeName, initialFeatureFlagState, reducer, sagas, [GetFeatureFlagAction]);
