import * as React from 'react';
import * as Loadable from 'react-loadable';

import { LoaderSection } from './LoaderSection';

/**
 * Default loading component to display a loading message
 *
 * @param props - The properties
 */

export const Loading: React.FunctionComponent<Loadable.LoadingComponentProps> = props => {
  const { error, pastDelay } = props;

  if (error) {
    return <div>Error!</div>;
  } else if (pastDelay) {
    return <LoaderSection />;
  }
  return null;
};

export default Loading;
