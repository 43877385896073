import React from 'react';

import BurgerMenu from './BurgerMenu';
import './NavbarMenu.scss';

const NavbarMenu = ({
  toggleCopilotVisibility,
  enableCopilotForAccount
}: {
  toggleCopilotVisibility: () => void;
  enableCopilotForAccount: boolean;
}) => {
  return (
    <div className="navbarMenu">
      <BurgerMenu toggleCopilotVisibility={toggleCopilotVisibility} enableCopilotForAccount={enableCopilotForAccount} />
    </div>
  );
};

export default NavbarMenu;
