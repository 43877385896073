import { ai } from '../ApplicationInsightsProvider/ApplicationInsightsService';

const redirectEventName = 'LearnUrlRedirect';

/**
 * Tracks Learn Url redirect event
 *
 * @param {string} learnUrl Search ID from the response header 'x-ms-azs-searchid'
 */
const trackLearnUrlRedirect = (learnUrl: string | undefined) => {
  ai.appInsights!.trackEvent(
    {
      name: redirectEventName
    },
    {
      learnUrl
    }
  );
};

export { trackLearnUrlRedirect };
