import { AnyAction, Reducer } from 'redux';

import { EndpointActions, EndpointActionSuffix } from './EndpointActions';
import { IEndpointState } from './EndpointState';

/**
 * An apparatus that provides a base reducer and state to automatically augment the current store's state
 * with common flags related to an Api endpoint's behaviors (ex: loading, errors, etc)
 */
export class EndpointReductor<State extends IEndpointState> {
  /** Represents the initial state for all data in the slice */
  private readonly _initialState: State;

  /** A list of endpoint action names to filter on */
  private readonly _endpointActionNames: string[];

  /**
   * @class
   * @param initialState The initial state for this slice of the store
   * @param endpointActions A list of endpoint actions tht should be intercepted. All others endpoints will be ignored.
   */
  constructor(initialState: State, endpointActions: Array<EndpointActions<any, any>>) {
    this._initialState = initialState;
    this._endpointActionNames = endpointActions.map((actions: EndpointActions<any, any>) => actions.name);
  }

  /**
   * The base reducer to process the state
   *
   * @param state - Initial state
   * @param action - Action to process the state
   */
  public reduce: Reducer<State> = (state: State = this._initialState, action: AnyAction): State => {
    const [name, suffix] = this.getEndpointActionNameParts(action);

    if (name && this._endpointActionNames.includes(name)) {
      switch (suffix) {
        case EndpointActionSuffix.Execute:
          return {
            ...state,
            isFetching: true
          } as State;
        case EndpointActionSuffix.ExecuteSuccess:
          return {
            ...state,
            isFetching: false,
            isFetched: true,
            isError: false,
            response: action.meta
          } as State;
        case EndpointActionSuffix.ExecuteError:
          return {
            ...state,
            isFetching: false,
            // TODO: The isFetched should be set to false, but the app logic should be updated to handle the differentiation
            //  between isFetched and isError
            isFetched: true,
            isError: true,
            response: action.meta,
            errorMessage: action.payload
          } as State;
        case EndpointActionSuffix.Clear:
          return {
            ...this._initialState
          };
        default:
          return state;
      }
    } else {
      return state;
    }
  };

  /**
   * Gets the name of the EndpointAction, and the action suffix given the action itself.
   * Ex: GetProfile/ExecuteError => [GetProfile, /ExecuteError]
   *
   * @param {AnyAction} action The action to determine the name and suffix
   * @returns {string | null} The suffix if it is valid, otherwise null
   */
  private getEndpointActionNameParts = (action: AnyAction): [string | null, string | null] => {
    const { type } = action;

    if (type && isNaN(Number(type))) {
      const typeValue = type as string;

      const suffixes = Object.values(EndpointActionSuffix);
      for (const suffix of suffixes) {
        if (suffix && typeValue.endsWith(suffix)) {
          const name = type.replace(suffix, '');
          return [name, suffix];
        }
      }
    }

    return [null, null];
  };
}
