import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import { SSOProvider } from '../SSOProvider/SSOProvider';
import EulaProtection from '../EulaProtection/EulaProtection';
import App from './App';
import ApplicationLoadingSpinner from '../ApplicationLoadingSpinner/ApplicationLoadingSpinner';
import { useAuthenticator } from '../../hooks';

export const AuthenticatedApp: React.FunctionComponent = () => {
  useAuthenticator();
  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        <SSOProvider>
          <EulaProtection>
            <App />
          </EulaProtection>
        </SSOProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <ApplicationLoadingSpinner />
      </UnauthenticatedTemplate>
    </React.Fragment>
  );
};

export default React.memo(AuthenticatedApp);
