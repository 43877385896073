import React, { createContext, useState, useMemo, useContext, useCallback, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { OptionalIntlConfig } from 'react-intl/src/components/provider';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IApplicationState } from '../../store';
import { disableTermsAndConditions, QueryStringParam } from '../../utilities';

const LOCALE_CACHE_KEY = 'locale';
const DEFAULT_LOCALE = {
  locale: 'en-US',
  englishName: 'English (US)',
  displayName: 'English (US)',
  rtl: false,
  visible: true
};

export interface ILocale {
  locale: string;
  englishName: string;
  displayName: string;
  rtl: boolean;
  visible: boolean;
}

interface ILocaleContext {
  locale: string;
  locales: ILocale[];
  defaultLocale: string;
  setLocale: (locale: string, forceReload?: boolean) => void;
  setDefaultLocale: (locale: string) => void;
}

const defaultLocaleContext: ILocaleContext = {
  locale: DEFAULT_LOCALE.locale,
  defaultLocale: DEFAULT_LOCALE.locale,
  locales: [DEFAULT_LOCALE],
  setLocale: (locale: string, forceReload?: boolean) => {},
  setDefaultLocale: (locale: string) => {}
};

export const LocaleContext = createContext<ILocaleContext>(defaultLocaleContext);
export type LocalizedMessages = OptionalIntlConfig['messages'];

type LocalizationProviderConfig = OptionalIntlConfig & {
  locales: ILocale[];
  storage?: Storage;
  localeLoader?: (locale: string) => Promise<LocalizedMessages>;
};

export const LocalizationProvider: React.FunctionComponent<LocalizationProviderConfig> = props => {
  const { children, locales, storage, localeLoader, ...providerProps } = props;
  const [featureFlags] = useSelector((state: IApplicationState) => [state.FeatureFlagStore?.featureFlags]);
  const history = useHistory();

  useEffect(() => {
    if (featureFlags.length > 0) {
      const disableEsiTerms = featureFlags?.filter(d => d?.flagName === disableTermsAndConditions)?.map(m => m?.isEnabledFlag)[0];
      if (!disableEsiTerms) {
        if (sessionStorage.getItem(QueryStringParam.WorkEmailAddress)) {
          if (!sessionStorage.getItem(QueryStringParam.IsESITermsAccepted)) {
            const pathName = window.location.pathname.split('/');
            if (
              pathName &&
              pathName[1] &&
              pathName[1] !== '' &&
              pathName[1].toLowerCase() !== 'esiagreement' &&
              pathName[1].toLowerCase() !== 'aadlogin' &&
              pathName[1].toLowerCase() !== 'about' &&
              pathName[1].toLowerCase() !== 'locale'
            ) {
              history.push({
                pathname: '/esiAgreement/',
                state: {
                  pathName: window.location.pathname,
                  search: window.location.search
                }
              });
              return;
            }
          }
        }
      }
    }
  }, [featureFlags, featureFlags.length, history]);

  useEffect(() => {
    if (storage) {
      const cachedLocale = storage.getItem(LOCALE_CACHE_KEY);
      if (cachedLocale) {
        setLocale(cachedLocale);
      } else {
        storage.setItem(LOCALE_CACHE_KEY, locale);
      }
    }

    const deliveryId = new URLSearchParams(window.location.search.toLowerCase()).get(QueryStringParam.DeliveryId);
    if (deliveryId) {
      sessionStorage.setItem(QueryStringParam.DeliveryId, deliveryId ?? '');
    }

    const query = new URLSearchParams(window.location.search.toLowerCase()).get(QueryStringParam.q);
    if (query) {
      sessionStorage.setItem(QueryStringParam.q, query ?? '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [locale, _setLocale] = useState(providerProps.locale || defaultLocaleContext.locale);
  const [defaultLocale, setDefaultLocale] = useState(providerProps.locale || defaultLocaleContext.locale);
  const [messages, setMessages] = useState<LocalizedMessages>(providerProps.messages);

  const setLocale = useCallback(
    async (locale: string, forceReload?: boolean) => {
      const loc = locales.find(l => l.locale === locale) || locales.find(l => l.locale === defaultLocale);
      if (loc) {
        if (storage) {
          storage.setItem(LOCALE_CACHE_KEY, loc.locale);
        }

        if (forceReload) {
          _setLocale(prev => loc.locale);
          window.location.reload();
        } else if (localeLoader) {
          const localeData = await localeLoader(loc.locale);
          setMessages(localeData);
          _setLocale(loc.locale);

          document.documentElement.lang = loc.locale;
          document.documentElement.dir = loc.rtl ? 'rtl' : 'ltr';
        } else {
          throw new Error('Attempted to load a locale without registering a localeLoader handler');
        }
      } else {
        throw new Error(`Attempted to set an unregistered locale "${locale}"`);
      }
    },
    [defaultLocale, localeLoader, locales, storage]
  );

  const localeMemo = useMemo<ILocaleContext>(
    () => ({
      locale,
      locales,
      defaultLocale,
      setLocale,
      setDefaultLocale
    }),
    [defaultLocale, locale, locales, setLocale]
  );

  return (
    <LocaleContext.Provider value={localeMemo}>
      <IntlProvider {...providerProps} locale={locale} defaultLocale={defaultLocale} messages={messages}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export const useLocale = () => useContext(LocaleContext);
