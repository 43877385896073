import { Saga } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import { EndpointSaga, IEndpointSagaDictionary } from '.';

export interface IAnthology {
  /** The name of the anthology */
  name: string;

  /** The root saga of the anthology */
  saga: Saga;
}

/**
 * SagaRegistry enables dynamic sagas.
 * It enables sagas to be added to the store after the store is created.
 * See more: https://manukyan.dev/posts/2019-04-15-code-splitting-for-redux-and-optional-redux-saga/
 */
class SagaRegistry {
  /** A listener to notify when changes occur to the saga map */
  private _emitChange: any;

  /** A map of all registered sagas */
  private _anthologies: Map<string, Saga> = new Map<string, Saga>();

  /**
   * Registers a saga against the middleware
   *
   * @param name - The name of the anthology
   * @param saga - The root saga of the anthology
   */
  public registerSaga = (name: string, saga: Saga): void => {
    if (this.isRegistered(name)) {
      return;
    }

    this._anthologies.set(name, saga);

    if (this._emitChange) {
      this._emitChange(saga);
    }
  };

  /**
   * Take a dictionary of EndpointSagas and registers a new root saga
   *
   * @param name - The name of the anthology
   * @param endpointSagas - endpoint sagas
   */
  public registerAnthology = (name: string, endpointSagas: IEndpointSagaDictionary): IAnthology => {
    const saga = this.getAnthologySaga(endpointSagas);
    this.registerSaga(name, saga);

    return {
      name,
      saga
    };
  };

  /**
   * Set the listener to notify when there are changes to the map of sagas
   *
   * @param listener - the listener
   */
  public setChangeListener(listener: any): void {
    this._emitChange = listener;
    this._anthologies.forEach((saga: Saga) => this._emitChange(saga));
  }

  /**
   * Takes a dictionary of EndpointSagas and returns a root Saga
   *
   * @param sagas - The root saga of the anthology
   */
  private getAnthologySaga = (sagas: IEndpointSagaDictionary): Saga => {
    return function*(): IterableIterator<any> {
      yield all(Object.values(sagas).map((saga: EndpointSaga<any, any>) => fork(saga.watcher)));
    };
  };

  /**
   * Determines if a saga is registered given it's name
   *
   * @param name - The name of the anthology
   */
  private isRegistered = (name: string): boolean => this._anthologies.has(name);
}

export const sagaRegistry = new SagaRegistry();
