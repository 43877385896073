import { applyMiddleware, combineReducers, compose, createStore, ReducersMapObject, Store } from 'redux';
import createSagaMiddleware, { Saga } from 'redux-saga';

import { IFeatureFlagState, initialFeatureFlagState } from './stores/featureFlag';
import { reducerRegistry, sagaRegistry } from './endpoint';
import { IAccountLinkingState, initialAccountLinkingState } from './stores/accountLinking';
import { ICertificationState, initialCertificationState } from './stores/certification';
import { IEnrollmentPlanState, initialEnrollmentPlanState } from './stores/enrollmentPlan';
import { ILearningPathState, initialLearningPathState } from './stores/learningPath';
import { initialUserState, IUserState } from './stores/user';
import { initialCopilotState, ICopilotState } from './stores/copilot';
import { initialDeliveryState, IDeliverySate } from './stores/msDelivery';
import { initialEventRegistrationState, IEventRegistrationState } from './stores/eventRegistration';
import { initialUpdateAccountState, IUpdateAccountState } from './stores/updateAccount';
import { initialRequestAdditionalTokensState, IRequestAdditionalTokensState } from './stores/additionalTokens';

// The top-level state object representing all slices of state for the application
export interface IApplicationState {
  /** The user state */
  UserStore: IUserState;

  /** The user's learning path state */
  LearningPathStore: ILearningPathState;

  /** The user's certification state */
  CertificationStore: ICertificationState;

  /** The user's enrollment plan state */
  EnrollmentPlanStore: IEnrollmentPlanState;

  /** The user's linked accounts state */
  AccountLinkingStore: IAccountLinkingState;

  /** The user's Delivery Details state */
  DeliveryStore: IDeliverySate;

  /** The Event Registration state */
  EventRegistrationStore: IEventRegistrationState;

  /** The  update account state */
  UpdateAccountStore: IUpdateAccountState;

  /** The Feature flag state */
  FeatureFlagStore: IFeatureFlagState;

  /** The  request Additiional tokens  state */
  RequestAdditionalTokensStore: IRequestAdditionalTokensState;

  CopilotStore: ICopilotState;
}

const initialAppState: IApplicationState = {
  UserStore: initialUserState,
  LearningPathStore: initialLearningPathState,
  CertificationStore: initialCertificationState,
  EnrollmentPlanStore: initialEnrollmentPlanState,
  AccountLinkingStore: initialAccountLinkingState,
  DeliveryStore: initialDeliveryState,
  EventRegistrationStore: initialEventRegistrationState,
  UpdateAccountStore: initialUpdateAccountState,
  FeatureFlagStore: initialFeatureFlagState,
  RequestAdditionalTokensStore: initialRequestAdditionalTokensState,
  CopilotStore: initialCopilotState
};

/**
 * Configuring store with default state and platform middleware applied.
 */
const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const defaultMiddleware = [sagaMiddleware];
  const rootReducer = combineReducers(reducerRegistry.getReducers());
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const reduxStore: Store = createStore<unknown, any, any, any>(
    rootReducer,
    initialAppState,
    composeEnhancers(applyMiddleware(...defaultMiddleware))
  );

  reducerRegistry.setChangeListener((reducers: ReducersMapObject) => {
    reduxStore.replaceReducer(combineReducers(reducers));
  });

  sagaRegistry.setChangeListener((saga: Saga) => {
    sagaMiddleware.run(saga);
  });

  return reduxStore || undefined;
};

export const store = configureStore();
