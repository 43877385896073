import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';

import { IRequestResponse } from '../../models';

type SuccessResponse = Omit<IRequestResponse<any>, 'data' | 'config'>;

export enum EndpointActionSuffix {
  Execute = '/Execute',
  ExecuteSuccess = '/ExecuteSuccess',
  ExecuteError = '/ExecuteError',
  Clear = '/Clear'
}

/**
 * Identifies the primary Actions that can occur against an endpoint.
 * This may be extended to add additional actions when needed.
 */
export class EndpointActionTypes {
  /** A collection of all the names given to the instantiated classes, used for validation of name uniqueness */
  private static _namesList: string[] = [];

  /** Executes the action against an endpoint */
  public readonly Execute: string;

  /** Signals a successful execution against the endpoint */
  public readonly ExecuteSuccess: string;

  /** Signals an unusuccessful execution against the endpoint */
  public readonly ExecuteError: string;

  /** Signals that the data stored from an execution should be cleared from the client */
  public readonly Clear: string;

  constructor(name: string) {
    if (!name) {
      throw Error('EndpointActionType names must be a valid string.');
    }

    if (EndpointActionTypes._namesList.includes(name)) {
      throw Error(`EndpointActionType names must be unique. The name "${name}" already exists.`);
    } else {
      EndpointActionTypes._namesList.push(name);
    }

    this.Execute = name + EndpointActionSuffix.Execute;
    this.ExecuteSuccess = name + EndpointActionSuffix.ExecuteSuccess;
    this.ExecuteError = name + EndpointActionSuffix.ExecuteError;
    this.Clear = name + EndpointActionSuffix.Clear;
  }
}

/**
 * The default contract of a created action object
 */
export interface IAction<RequestBody> {
  /** Identifies the type of action */
  type: string;

  /** Represents the payload to be sent with the http request */
  payload?: RequestBody;

  /** Represents the meta data */
  meta?: any;

  /** Represents the error data */
  error?: any;
}

/**
 * Interface to enforce the basic Actions that can occur against an endpoint
 *
 * @typeparam RequestBody The request body to be sent when executing the request
 * @typeparam ResponseBody The model of the response body expected from the request response
 */
export interface IEndpointActions<RequestBody, ResponseBody> {
  /** The name of the valid and unique name of the endpoint */
  readonly name: string;

  /** Represents the names of each Action type */
  readonly ActionTypes: EndpointActionTypes;

  /** Should create the Action to execute the endpoint */
  Execute(parameters?: RequestBody): AnyAction;

  /** Should create the Action signaling a successful execution against the endpoint */
  ExecuteSuccess(data: ResponseBody, response?: Omit<IRequestResponse<any>, 'data' | 'config'>): AnyAction;

  /** Should create the Action signaling an unsuccessful execution against the endpoint */
  ExecuteError(errorMessage: string, response?: IRequestResponse<ResponseBody>): AnyAction;

  /** Should create the Action to signal the need to clear all data store from an execution */
  Clear(data?: boolean): AnyAction;
}

/**
 * Creates the Actions that can occur against an endpoint
 *
 * @typeparam RequestBody The request body to be sent when executing the request
 * @typeparam ResponseBody The model of the response body expected from the request response
 */
export class EndpointActions<RequestBody, ResponseBody> implements IEndpointActions<RequestBody, ResponseBody> {
  /** A collection of all the names given to the instantiated classes, used for validation of name uniqueness */
  private static _namesList: string[] = [];

  /** The name of the the endpoint */
  public readonly name: string;

  /** Represents the names of each Action type */
  public readonly ActionTypes: EndpointActionTypes;

  constructor(name: string) {
    if (!name) {
      throw Error('EndpointActions names must be a valid string.');
    }

    if (EndpointActions._namesList.includes(name)) {
      throw Error(`EndpointActions names must be unique. The name "${name}" already exists.`);
    } else {
      EndpointActions._namesList.push(name);
    }

    this.name = name;
    this.ActionTypes = new EndpointActionTypes(name);
  }

  /**
   * Creates the Action to execute against the endpoint
   *
   * @param parameters Parameters to be passed to the endpoint in the requet body
   */
  public Execute = (parameters?: RequestBody) => action(this.ActionTypes.Execute, parameters);

  /**
   * Creates the Action to signal a successful execution against the endpoint
   *
   * @param data The response body from the endpoint
   * @param response The response object
   */
  public ExecuteSuccess = (data: ResponseBody, response?: SuccessResponse) => action(this.ActionTypes.ExecuteSuccess, data, response);

  /**
   * Creates the Action to signal an unsuccessful execution against the endpoint
   *
   * @param errorMessage The message that describes the error
   * @param response The response object
   */
  public ExecuteError = (errorMessage: string, response?: IRequestResponse<ResponseBody>) =>
    action(this.ActionTypes.ExecuteError, errorMessage, response);

  /**
   * Creates the Action to signal that all data from an execution should be cleared from the client
   *
   * @param data
   */
  public Clear = (data?: boolean) => action(this.ActionTypes.Clear, data);
}
