import { ai } from '../ApplicationInsightsProvider/ApplicationInsightsService';

const redirectEventName = 'SSOSignIn';

/**
 * Tracks SSO sign in events
 *
 * @param {string} source The source the SSO is coming from
 * @param {string} result The type of resolution for the SSO
 * @param {string} failureReason The reason for the SSO failure if it has occurred
 */
const trackSSOSignIn = (source: string | undefined, result: string | undefined, failureReason: string | undefined) => {
  ai.appInsights!.trackEvent(
    {
      name: redirectEventName
    },
    {
      source,
      result,
      failureReason
    }
  );
};

export { trackSSOSignIn };
