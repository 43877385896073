import Loadable from 'react-loadable';
import { RouteConfig } from 'react-router-config';

import Loading from './components/Loading/Loading';

export const routeConfig: RouteConfig[] = [
  {
    path: '/deliveryonly',
    component: Loadable({
      loader: () => import('./pages/Delivery/DeliveryOnly'),
      loading: Loading
    }),
    exact: true
  },
  {
    path: '/Delivery',
    component: Loadable({
      loader: () => import('./pages/Delivery/Delivery'),
      loading: Loading
    }),
    exact: true
  },
  {
    path: '/deliverymultiday',
    component: Loadable({
      loader: () => import('./pages/Delivery/Delivery'),
      loading: Loading
    }),
    exact: true
  },
  {
    path: '/gamelearning',
    component: Loadable({
      loader: () => import('./pages/Delivery/Delivery'),
      loading: Loading
    }),
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/AccountLinking/AccountModal'),
      loading: Loading
    }),
    path: '/accountlinking/accountmodal',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/Onboarding/Step1'),
      loading: Loading
    }),
    path: '/onboarding/step1',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/Onboarding/Step2'),
      loading: Loading
    }),
    path: '/onboarding/step2',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/Onboarding/Step3'),
      loading: Loading
    }),
    path: '/onboarding/step3',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/Welcome/Welcome'),
      loading: Loading
    }),
    path: '/register/',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/Welcome/Welcome'),
      loading: Loading
    }),
    path: '/',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/Preference/UpdatePreferences'),
      loading: Loading
    }),
    path: '/settings/',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/Locale/Locale'),
      loading: Loading
    }),
    path: '/locale/',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/Registrations/Registrations'),
      loading: Loading
    }),
    path: '/registrations',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/Registrations/Registrations'),
      loading: Loading
    }),
    path: '/coursedetails',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/StepsSuccess/BePrepared'),
      loading: Loading
    }),
    path: '/beprepared',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/StepsSuccess/BuildConfidence'),
      loading: Loading
    }),
    path: '/buildconfidence',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/StepsSuccess/ReviewPath'),
      loading: Loading
    }),
    path: '/reviewpath',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/StepsSuccess/CelebrateSkills'),
      loading: Loading
    }),
    path: '/celebrateskills',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/Register/Register'),
      loading: Loading
    }),
    path: '/register/agree/'
  },
  {
    component: Loadable({
      loader: () => import('./pages/EmailConfirmation/EmailValidation'),
      loading: Loading
    }),
    path: '/emailvalidation/',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/EmailConfirmation/AadLogin'),
      loading: Loading
    }),
    path: '/AadLogin/',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/Onboarding/ESIAgreement'),
      loading: Loading
    }),
    path: '/esiAgreement/',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/Landing/Landing'),
      loading: Loading
    }),
    path: '/landing/',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/FindMoreClasses/FindMoreClasses'),
      loading: Loading
    }),
    path: '/findmoreclasses/',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/GetCertification/GetCertification'),
      loading: Loading
    }),
    path: '/getcertification/',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/About/About'),
      loading: Loading
    }),
    path: '/about/',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/Support/UpdateAccount'),
      loading: Loading
    }),
    path: '/support/',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/Help/Help'),
      loading: Loading
    }),
    path: '/help/',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/Search/Search'),
      loading: Loading
    }),
    path: '/search',
    exact: true
  },
  {
    component: Loadable({
      loader: () => import('./pages/NotFound/NotFound'),
      loading: Loading
    })
  }
];
