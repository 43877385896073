import React from 'react';
import { Dialog, DialogType, Link } from '@fluentui/react';
import { FormattedMessage } from 'react-intl';

import { useDisclosure, useLogout } from '../../../hooks';
import { RightSideBarNavigation } from '../../SideBarNavigation/RightSideBarNavigation';
import './RightNavMenu.scss';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';

interface IComponentProps {
  isOpen: boolean;
  /** showPersona - general info page */
  onClose?: () => void;
  toggleCopilotVisibility: () => void;
  enableCopilotForAccount: boolean;
}

export const RightNavMenu: React.FunctionComponent<IComponentProps> = props => {
  const { isOpen, onClose, toggleCopilotVisibility, enableCopilotForAccount } = props;

  const dialogContentProps = {
    type: DialogType.close
  };

  const modalProps = {
    isBlocking: true,
    containerClassName: 'rightNavDialog'
  };
  const account = useAuthenticatedUser();
  const signout = useLogout();

  const displayName = account?.firstName || '';

  const { onToggle } = useDisclosure(false);

  const enterPressed = (event: any) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      setTimeout(function() {
        onToggle();
      }, 100);
    }
  };

  const onsignOutLinkClick = () => {
    const isSessionExpired = localStorage.getItem('isSessionExpired');
    if (isSessionExpired && isSessionExpired.toLowerCase() === 'yes') {
      localStorage.setItem('isSessionExpired', 'no');
    }
    signout();
  };

  return (
    <Dialog hidden={isOpen} dialogContentProps={dialogContentProps} modalProps={modalProps}>
      <div className="ms-Dialog-inner" role="region">
        <RightSideBarNavigation
          onDismiss={onClose}
          toggleCopilotVisibility={toggleCopilotVisibility}
          enableCopilotForAccount={enableCopilotForAccount}
          isOpen={isOpen}
        ></RightSideBarNavigation>
      </div>
      <div className="ms-Dialog-footer">
        <div className="linkContainer">
          <span className="welcomeName">
            <FormattedMessage defaultMessage="Hello" /> <span className="headerBarFirst ms-fontWeight-bold">{displayName}!</span>
          </span>
          <Link
            id="signOutLink"
            role="button"
            tabIndex={0}
            onKeyDown={enterPressed}
            onClick={onsignOutLinkClick}
            className="ms-fontSize-13 ms-fontWeight-bold footerLink"
          >
            <FormattedMessage defaultMessage="Sign out" />
          </Link>
        </div>
      </div>
    </Dialog>
  );
};

export default RightNavMenu;
