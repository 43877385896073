import { AnyAction, Reducer } from 'redux';

import {
  IEventRegistrationRequest,
  IEventRegistrationResponse,
  ICancelRegistrationRequest,
  ICancelRegistrationResponse,
  IEventRegistrations,
  ICustomErrorMessage,
  ICancelWaitlistRegistrationRequest,
  ICancelWaitlistRegistrationResponse,
  IConfirmRegistrationRequest,
  IConfirmRegistrationResponse
} from '../../../models';
import {
  IGetEventSessionsResponse,
  IPatchEventSessionsRequest,
  IPatchEventSessionsResponse
} from '../../../models/EventManagement/EventSessions';
import { IPostResendInviteRequest, IPostResendInviteResponse } from '../../../models/EventManagement/PostResendInvite';
import { ErrorMessageMapper } from '../../../utilities';
import { EndpointStateFactory, EndpointStore, IEndpointState } from '../../endpoint';
import {
  GetCreateRegistrationActions,
  GetCancelRegistrationActions,
  GetCancelWaitlistActions,
  GetConfirmRegistrationActions,
  GetEventSessionsActions,
  PatchEventSessionsActions,
  PostResendInviteActions
} from './actions';
import { sagas } from './sagas';
export * from './actions';

/** The name of the state, this will be the Redux property that data is stored under */
const storeName = 'EventRegistrationStore';

export interface IEventRegistrationState extends IEndpointState {
  /** The event registration state */
  readonly eventRegistration: IEventRegistrations;
  readonly eventRegistrationRequest: IEventRegistrationRequest;
  readonly eventRegistrationResponse: IEventRegistrationResponse;
  readonly cancelRegistrationRequest: ICancelRegistrationRequest;
  readonly cancelRegistrationResponse: ICancelRegistrationResponse;
  readonly cancelWaitlistRequest: ICancelWaitlistRegistrationRequest;
  readonly cancelWaitlistResponse: ICancelWaitlistRegistrationResponse;
  readonly confirmRegistrationRequest: IConfirmRegistrationRequest;
  readonly confirmRegistrationResponse: IConfirmRegistrationResponse;
  readonly customErrorMessage: ICustomErrorMessage | null;
  readonly eventSessions: Record<IGetEventSessionsResponse['deliveryId'], IGetEventSessionsResponse['sessions']>;
  readonly patchEventSessionsRequest: IPatchEventSessionsRequest[];
  readonly patchEventSessionsResponse: IPatchEventSessionsResponse;
  readonly postResendInviteRequest: IPostResendInviteRequest;
  readonly postResendInviteResponse: IPostResendInviteResponse;
}

/** IState acts only as a helper type to make store logic easier to copy and paste */
type IState = IEventRegistrationState;

/**
 * The initial state of the endpoint store. Endpoint stores have base states (ex: isFetching, isFetched, etc.).
 *  We use the EndointStateFactory to make sure to populate these values with the correct defaults
 */
export const initialEventRegistrationState: IState = new EndpointStateFactory<IState>().getInitialState({
  eventRegistration: { attendees: [] },
  eventRegistrationRequest: {
    eventId: '',
    deliveryId: '',
    learnerId: '',
    eventregistrations: { attendees: [] }
  },
  cancelRegistrationRequest: {
    regId: '',
    deliveryId: '',
    learnerId: '',
    isDeliveryConfirmed: false
  },
  cancelRegistrationResponse: {
    errorCode: '',
    message: '',
    status: ''
  },
  cancelWaitlistRequest: {
    waitlistId: '',
    deliveryId: '',
    learnerId: ''
  },
  cancelWaitlistResponse: {
    isSuccess: false
  },
  confirmRegistrationRequest: {
    registrationId: '',
    deliveryId: '',
    learnerId: ''
  },
  confirmRegistrationResponse: {
    isSuccess: false
  },
  customErrorMessage: null,
  eventSessions: {},
  patchEventSessionsRequest: [{ sessionRegId: '', sendInvite: false }],
  patchEventSessionsResponse: { isSuccess: false },
  postResendInviteRequest: { email: '', msgroupid: '', eventregistrationid: '' },
  postResendInviteResponse: { status: '', message: '' }
});

/**
 * Reducer responsible for managing the slice of state that belongs to the store
 *
 * @param state - Initial event registration state
 * @param action - The action
 */
const reducer: Reducer<IState> = (state: IState = initialEventRegistrationState, action: AnyAction): IState => {
  switch (action.type) {
    case GetCancelRegistrationActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        cancelRegistrationResponse: action.payload
      };
    case GetCancelRegistrationActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case GetCancelWaitlistActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        cancelWaitlistResponse: {
          isSuccess: true
        }
      };
    case GetCancelWaitlistActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case GetConfirmRegistrationActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        confirmRegistrationResponse: {
          isSuccess: true
        }
      };
    case GetConfirmRegistrationActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case GetCreateRegistrationActions.ActionTypes.ExecuteSuccess: {
      return {
        ...state,
        eventRegistrationResponse: action.payload
      };
    }
    case GetCreateRegistrationActions.ActionTypes.ExecuteError:
    case PatchEventSessionsActions.ActionTypes.ExecuteError:
    case PostResendInviteActions.ActionTypes.ExecuteError: {
      if (action.meta) {
        return {
          ...state,
          isError: true,
          customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
        };
      } else {
        return state;
      }
    }
    case GetEventSessionsActions.ActionTypes.ExecuteSuccess: {
      const { deliveryId, sessions } = action.payload;
      return {
        ...state,
        eventSessions: {
          ...state.eventSessions,
          [deliveryId]: sessions.map((session: any) => ({ ...session, checked: false }))
        }
      };
    }
    case PatchEventSessionsActions.ActionTypes.ExecuteSuccess: {
      return {
        ...state,
        patchEventSessionsResponse: { isSuccess: action.payload.statusCode === 200 }
      };
    }
    case PostResendInviteActions.ActionTypes.ExecuteSuccess: {
      return {
        ...state,
        postResendInviteResponse: action.payload
      };
    }
    case PostResendInviteActions.ActionTypes.Clear: {
      return {
        ...state,
        postResendInviteResponse: { status: '', message: '' }
      };
    }
    default:
      return state;
  }
};

/** Responsible for handling the related state in the Redux store */
export const EventRegistrationStore = new EndpointStore<IState>(storeName, initialEventRegistrationState, reducer, sagas, [
  GetCreateRegistrationActions,
  GetCancelRegistrationActions,
  GetCancelWaitlistActions,
  GetConfirmRegistrationActions,
  GetEventSessionsActions,
  PatchEventSessionsActions,
  PostResendInviteActions
]);
