import { IRequestResponse } from '../../models';

export interface IEndpointState {
  /** Indicates whether the data is currently being fetched */
  readonly isFetching: boolean;

  /** Indicates whether the data has been fetched already */
  readonly isFetched: boolean;

  /** Indicates whether an error occured during fetch */
  readonly isError: boolean;

  /** Represents the response recieved from the endpoint */
  readonly response?: IRequestResponse<any>;

  /** Represents error text that resulted from the fetch operation */
  readonly errorMessage?: string;
}

/** Represents the initial state that will be augmented onto the existing model for all endpoints */
export class EndpointStateFactory<State extends IEndpointState> {
  /** The default initial state of an endpoint */
  private static initialEndpointState: IEndpointState = {
    isFetched: false,
    isFetching: false,
    isError: false,
    response: undefined,
    errorMessage: undefined
  };

  /**
   * Takes a state object and adds the default endpoint state values
   *
   * @param initialState - The initial state
   */
  public getInitialState = (initialState: Partial<State>): State => {
    return {
      ...EndpointStateFactory.initialEndpointState,
      ...initialState
    } as State;
  };
}
