import { AnyAction, Reducer } from 'redux';

import {
  ILearningPath,
  ILearningPathCard,
  ILearningPathGroups,
  IMSClassesCatalog,
  ILearningPartners,
  ICustomErrorMessage
} from '../../../models';
import { ErrorMessageMapper } from '../../../utilities';
import { EndpointStateFactory, EndpointStore, IEndpointState } from '../../endpoint';
import {
  GetLearningPathActions,
  GetLearningPathCardActions,
  GetLearningPathGroupsActions,
  MSCatalogActions,
  GetLearningPartnersActions,
  MSSingleCatalogActions,
  GetMyDeliveriesActions,
  GetSearchDeliveriesStatusActions
} from './actions';
import { sagas } from './sagas';

export * from './actions';

/** The name of the state, this will be the Redux property that data is stored under */
const storeName = 'LearningPathStore';

export interface ILearningPathState extends IEndpointState {
  /** The certification state */
  readonly learningPath: ILearningPath;
  readonly learningPathCards: ILearningPathCard[];
  readonly learningPathGroups: ILearningPathGroups[];
  readonly msCatalog: IMSClassesCatalog[];
  readonly learningPartners: ILearningPartners[];
  readonly customErrorMessage: ICustomErrorMessage | null;
  readonly myDeliveries: IMSClassesCatalog[];
  readonly searchDeliveriesStatus: IMSClassesCatalog[];
}

/** IState acts only as a helper type to make store logic easier to copy and paste */
type IState = ILearningPathState;

/**
 * The initial state of the endpoint store. Endpoint stores have base states (ex: isFetching, isFetched, etc.).
 *  We use the EndointStateFactory to make sure to populate these values with the correct defaults
 */
export const initialLearningPathState: IState = new EndpointStateFactory<IState>().getInitialState({
  learningPath: {
    id: '',
    pathwayIdentifier: '',
    versionNumber: 0,
    isCurrent: false,
    customerRole: '',
    title: '',
    status: 0,
    groupedItems: []
  },
  learningPathCards: [],
  learningPathGroups: [],
  learningPartners: [],
  msCatalog: [],
  customErrorMessage: null,
  myDeliveries: [],
  searchDeliveriesStatus: []
});

/**
 * Reducer responsible for managing the slice of state that belongs to the store
 *
 * @param state - Initial Learning Path state
 * @param action - The action
 */
const reducer: Reducer<IState> = (state: IState = initialLearningPathState, action: AnyAction): IState => {
  switch (action.type) {
    case GetLearningPartnersActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        learningPartners: action.payload
      };
    case GetLearningPartnersActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case GetLearningPathActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        learningPath: action.payload
      };
    case GetLearningPathActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case GetLearningPathGroupsActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        learningPathGroups: action.payload
      };
    case GetLearningPathGroupsActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case GetLearningPathCardActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        learningPathCards: action.payload
      };
    case GetLearningPathCardActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case MSCatalogActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        msCatalog: action.payload
      };
    case MSCatalogActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case MSSingleCatalogActions.ActionTypes.ExecuteSuccess:
      const currentMSCatalog = [...state.msCatalog];
      const deliveryIndex = state.msCatalog.findIndex(catalog => catalog.deliveryId === action.payload[0].deliveryId);
      if (deliveryIndex > -1) {
        currentMSCatalog[deliveryIndex] = { ...currentMSCatalog[deliveryIndex], deliveryStatus: action.payload[0].deliveryStatus };
      }
      return {
        ...state,
        msCatalog: currentMSCatalog
      };
    case MSSingleCatalogActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case GetMyDeliveriesActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        myDeliveries: action.payload
      };
    case GetMyDeliveriesActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    case GetSearchDeliveriesStatusActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        searchDeliveriesStatus: action.payload
      };
    case GetSearchDeliveriesStatusActions.ActionTypes.ExecuteError:
      return {
        ...state,
        isError: true,
        customErrorMessage: ErrorMessageMapper.GET_ERROR(action.meta)
      };
    default:
      return state;
  }
};

/** Responsible for handling the related state in the Redux store */
export const LearningPathStore = new EndpointStore<IState>(storeName, initialLearningPathState, reducer, sagas, [
  GetLearningPathActions,
  GetLearningPathCardActions,
  GetLearningPathGroupsActions,
  MSCatalogActions,
  GetLearningPartnersActions,
  MSSingleCatalogActions,
  GetMyDeliveriesActions,
  GetSearchDeliveriesStatusActions
]);
