import { takeLeading } from 'redux-saga/effects';

import Api from '../../api/Api';
import { EndpointSaga, IEndpointSagaDictionary } from '../../endpoint';
import {
  IEventRegistrationRequest,
  IEventRegistrationResponse,
  ICancelRegistrationRequest,
  ICancelRegistrationResponse,
  ICancelWaitlistRegistrationResponse,
  ICancelWaitlistRegistrationRequest,
  IConfirmRegistrationRequest,
  IConfirmRegistrationResponse
} from '../../../models';
import {
  GetCreateRegistrationActions,
  GetCancelRegistrationActions,
  GetCancelWaitlistActions,
  GetConfirmRegistrationActions,
  GetEventSessionsActions,
  PatchEventSessionsActions,
  PostResendInviteActions
} from './actions';
import {
  IGetEventSessionsRequest,
  IGetEventSessionsResponse,
  IPatchEventSessionsRequest,
  IPatchEventSessionsResponse
} from '../../../models/EventManagement/EventSessions';
import { IPostResendInviteRequest, IPostResendInviteResponse } from '../../../models/EventManagement/PostResendInvite';

export const sagas: IEndpointSagaDictionary = {
  GetEventRegistrationSaga: new EndpointSaga<IEventRegistrationRequest, IEventRegistrationResponse>(
    GetCreateRegistrationActions,
    Api.CREATE_EVENT_REGISTRATION,
    takeLeading
  ),
  GetcancelEventRegistrationSaga: new EndpointSaga<ICancelRegistrationRequest, ICancelRegistrationResponse>(
    GetCancelRegistrationActions,
    Api.CANCEL_REGISTRATION,
    takeLeading
  ),
  GetcancelWaitlistSaga: new EndpointSaga<ICancelWaitlistRegistrationRequest, ICancelWaitlistRegistrationResponse>(
    GetCancelWaitlistActions,
    Api.CANCEL_WAITLIST,
    takeLeading
  ),
  GetconfirmEventRegistrationSaga: new EndpointSaga<IConfirmRegistrationRequest, IConfirmRegistrationResponse>(
    GetConfirmRegistrationActions,
    Api.CONFIRM_REGISTRATION,
    takeLeading
  ),
  GetEventSessionsSaga: new EndpointSaga<IGetEventSessionsRequest, IGetEventSessionsResponse>(
    GetEventSessionsActions,
    Api.GET_EventSessions,
    takeLeading
  ),
  PatchEventSessionsSaga: new EndpointSaga<IPatchEventSessionsRequest[], IPatchEventSessionsResponse>(
    PatchEventSessionsActions,
    Api.PATCH_EventSessions,
    takeLeading
  ),
  PostResendInviteSaga: new EndpointSaga<IPostResendInviteRequest, IPostResendInviteResponse>(
    PostResendInviteActions,
    Api.POST_ResendInvite,
    takeLeading
  )
};
