import {
  AccountInfo,
  AuthenticationResult,
  AuthError,
  InteractionRequiredAuthError,
  RedirectRequest,
  SilentRequest
} from '@azure/msal-browser';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { appSettings } from '../Settings';
import { ai } from '../components/ApplicationInsightsProvider/ApplicationInsightsService';

const defaultScopes = appSettings.config.defaultAuthScopes;
const msal = appSettings.config.msal;

export class TokenHandler {
  public static getToken = async (parameters?: SilentRequest): Promise<AuthenticationResult> => {
    // The parameters to be used when silently refreshing the token

    const defaultParams = {
      scopes: defaultScopes,
      account: msal.getAllAccounts()?.[0] || ({} as AccountInfo),
      ...parameters
    };
    try {
      return await msal.acquireTokenSilent(defaultParams);
    } catch (error) {
      ai.appInsights?.trackException({
        exception: new Error('ESIUI - AcquireTokenSilent'),
        severityLevel: SeverityLevel.Error,
        properties: { error }})
      return await TokenHandler.loginToRefreshToken(error, defaultParams);
    }
  };

  public static loginRequest = (parameters: RedirectRequest): void => {
    // The parameters to be used when silently refreshing the token
    const defaultParams = {
      ...parameters
    };
    msal.handleRedirectPromise().then(response => {
      msal.loginRedirect(defaultParams).catch((error)=>{
        ai.appInsights?.trackException({
          exception: new Error('ESIUI - Login Redirect'),
          severityLevel: SeverityLevel.Error,
          properties: { error }
        });
      })
    }).catch((error:any)=>{
      ai.appInsights?.trackException({
        exception: new Error('ESIUI- HandleRedirectPromise'),
        severityLevel: SeverityLevel.Error,
        properties: { error }
      });
    });
  };

  private static loginToRefreshToken = async (error: AuthError, parameters: RedirectRequest): Promise<AuthenticationResult> => {
    if (error instanceof InteractionRequiredAuthError) {
      await msal.acquireTokenRedirect(parameters).catch(()=>{
        ai.appInsights?.trackException({
          exception: new Error('ESIUI-LoginToRefreshToken-AcquireTokenRedirect'),
          severityLevel: SeverityLevel.Error,
          properties: { error }
        });
      });
      // Nothing will return here, the page will be redirected away from the app
      return new Promise<any>(resolve => resolve(null));
    } else {
      ai.appInsights?.trackException({
        exception: new Error('ESIUI-LoginToRefreshToken'),
        severityLevel: SeverityLevel.Error,
        properties: { error }
      });
      return new Promise<any>(resolve => resolve(null));
    }
  };
}
