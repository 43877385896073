import { takeLeading } from 'redux-saga/effects';

import Api from '../../api/Api';
import { EndpointSaga, IEndpointSagaDictionary } from '../../endpoint';
import { IUpdateAccountRequest, IUpdateAccountRequestResponse } from '../../../models/UpdateAccountRequest';
import {
  GetUpdateAccountWorkEmailOnlyActions,
  GetUpdateAccountActions,
  GetUpdateAccountOidEmailAddressOnlyActions,
  GetMultipleProfiles,
  DeleteDuplicateProfile,
  OfferTransHistoryActions
} from './actions';
import { IMultipleProfileRequest, IMultipleProfileResponse } from '../../../models/multipleProfileRequest';
import { IDeleteDuplicateProfileRequest, IDeleteDuplicateProfileResponse } from '../../../models/deleteDuplicateProfileRequest';

export const sagas: IEndpointSagaDictionary = {
  UpdateAccountWorkEmailOnlySaga: new EndpointSaga<IUpdateAccountRequest[], IUpdateAccountRequestResponse[]>(
    GetUpdateAccountWorkEmailOnlyActions,
    Api.UPDATE_ACCOUNT_WorkEmailOnly,
    takeLeading
  ),
  UpdateAccountSaga: new EndpointSaga<IUpdateAccountRequest[], IUpdateAccountRequestResponse[]>(
    GetUpdateAccountActions,
    Api.UPDATE_ACCOUNT,
    takeLeading
  ),
  UpdateAccountOidEmailAddressOnlySaga: new EndpointSaga<IUpdateAccountRequest[], IUpdateAccountRequestResponse[]>(
    GetUpdateAccountOidEmailAddressOnlyActions,
    Api.UPDATE_ACCOUNT_OID_EmailAddressOnly,
    takeLeading
  ),
  GetProfilesSaga: new EndpointSaga<IMultipleProfileRequest[], IMultipleProfileResponse[]>(
    GetMultipleProfiles,
    Api.GET_MULTIPLE_PROFILES,
    takeLeading
  ),
  DeleteDuplicateProfileSaga: new EndpointSaga<IDeleteDuplicateProfileRequest[], IDeleteDuplicateProfileResponse[]>(
    DeleteDuplicateProfile,
    Api.DELETE_DUPLICATE_PROFILE,
    takeLeading
  ),
  OfferTransactionSaga: new EndpointSaga<string, any>(OfferTransHistoryActions, Api.Get_OfferTransactionHistory, takeLeading)
};
