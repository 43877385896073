import { appSettings } from '../Settings';

export const useLogout = (silent = false) => {
  if (silent) {
    return async () => {
      sessionStorage.clear();
      await appSettings.config.msal.logoutRedirect({ onRedirectNavigate: _ => false });
    };
  }
  return async () => {
    sessionStorage.clear();
    await appSettings.config.msal.logoutRedirect();
  };
};
