import { Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import './LoaderSection.scss';

interface IComponentProps {
  customText?: string;
}

export const LoaderSection: React.FunctionComponent<IComponentProps> = props => {
  const { customText } = props;
  const intl = useIntl();
  const defaultText = intl.formatMessage({ defaultMessage: 'Loading the learning experience...' });
  const textToDisplay = customText || defaultText;

  return (
    <div className="loader">
      <Spinner size={SpinnerSize.large} label={textToDisplay} ariaLabel={textToDisplay} />
    </div>
  );
};
