import { ai } from './ApplicationInsightsService';

const redirectEventName = 'CopilotSSOFailure';
const openEventName = 'CopilotChatOpened';
const disconnectEventName = 'CopilotConnectionRejected';

/**
 * Tracks Copilot SSO Failure events
 *
 * @param connectionName The OAuth connection name being used for Copilot SSO
 */
const trackCopilotSSOFailure = (connectionName: string | undefined) => {
  ai.appInsights!.trackEvent(
    {
      name: redirectEventName
    },
    {
      connectionName
    }
  );
};

/**
 * Tracks Copilot Chat open events
 */
const trackCopilotChatOpened = () => {
  ai.appInsights!.trackEvent({
    name: openEventName
  });
};

/**
 * Tracks Copilot Chat connection failure events
 */
const trackCopilotChatConnectionRejected = () => {
  ai.appInsights!.trackEvent({
    name: disconnectEventName
  });
};

export { trackCopilotSSOFailure, trackCopilotChatOpened, trackCopilotChatConnectionRejected };
